import { useEffect } from "react";

type Props = {
    currentTestDataIndex: number | undefined;
    setTestDataIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
    numData: number;
};

export const useTestDataArrowKeys = ({ currentTestDataIndex, setTestDataIndex, numData }: Props) => {
    useEffect(() => {
        const keyPressHandler = ({ key }: KeyboardEvent) => {
            if (key === "ArrowRight") {
                setTestDataIndex(prevIndex => {
                    if (prevIndex === undefined) {
                        return 0;
                    }

                    return ((prevIndex ?? 0) + 1 + numData) % numData;
                });
            }

            if (key === "ArrowLeft") {
                setTestDataIndex(prevIndex => {
                    if (prevIndex === undefined) {
                        return 0;
                    }

                    return ((prevIndex ?? 0) - 1 + numData) % numData;
                });
            }
        };

        window.addEventListener("keyup", keyPressHandler);

        return () => {
            window.removeEventListener("keyup", keyPressHandler);
        };
    }, [currentTestDataIndex]);

    return currentTestDataIndex;
};
