export const image1PreviewResponse = [
    {
        title: "CRAVE SALAD & BREAD SERVICE",
        price: 45,
        category: "salad"
    },
    {
        title: "STEAK MEDALLIONS",
        description: "certified angus beef, mashed potatoes, grilled asparagus & CRAVE signature steak sauce",
        price: 45,
        category: "entree selections"
    },
    {
        title: "SALMON PICCATA",
        description:
            "seared verlasso salmon filet served over linguini tossed in lemon caper, chili flakes, lemon beurre blanc, basil & fried capers",
        price: 45,
        category: "entree selections"
    },
    {
        title: "LEMON GARLIC CHICKEN",
        description:
            "all natural certified angus beef braised short ribs with garlic, rosemary & thyme with, mashed potatoes, broccolini & lemon garlic butter sauce",
        price: 45,
        category: "entree selections"
    },
    {
        title: "VEGAN STIR FRY",
        description:
            "a medley of fresh vegetables, garlic & ginger sautéed with soy chili sauce, served over coconut jasmine rice & finished with cilantro & chopped peanuts",
        price: 45,
        category: "entree selections"
    },
    {
        title: "S'MORES BROWNIE",
        price: 45,
        category: "dessert"
    },
    {
        title: "CAESAR SALAD & BREAD SERVICE",
        price: 40,
        category: "salad"
    },
    {
        title: "STEAK & MUSHROOM FETTUCCINE",
        description:
            "steak medallions with sautéed garlic, mushroom conserva, caramelized onions, mornay sauce & joseph’s fresh fettuccine, with asparagus tossed in lemon oil",
        price: 40,
        category: "entree selections"
    },
    {
        title: "RED QUINOA & GRILLED SALMON SALAD",
        description:
            "red quinoa, revolt greens arugula, roasted beets & fresh orange segments tossed in blood orange vinaigrette, with a grilled verlasso salmon filet & lemon butter sauce",
        price: 40,
        category: "entree selections"
    },
    {
        title: "CHICKEN STIR FRY",
        description:
            "all natural chicken sautéed with garlic, ginger & fresh vegetable medley, soy chili sauce, served over coconut jasmine rice & finished with cilantro & chopped peanuts",
        price: 40,
        category: "entree selections"
    },
    {
        title: "VEGGIE MAC & CHEESE",
        description: "blend of five cheeses melted into cream sauce, cavatappi & topped with breadcrumbs",
        price: 40,
        category: "entree selections"
    },
    {
        title: "CHOCOLATE CAKE",
        price: 40,
        category: "dessert"
    },
    {
        title: "CHICKEN WILD RICE & BREAD SERVICE",
        description: '"',
        price: 30,
        category: "soup"
    },
    {
        title: "CRAVE GRILLED STEAK SALAD",
        description:
            "marinated steak medallions on revolt greens spring mix in balsamic vinaigrette, topped with candied walnuts, goat cheese & balsamic glaze",
        price: 30,
        category: "entree selections"
    },
    {
        title: "SHRIMP RICE BOWL",
        description: "hoisin glazed shrimp, coconut rice",
        price: 30,
        category: "entree selections"
    },
    {
        title: "CHICKEN ROSA",
        description:
            "hand breaded parmesan chicken on a bed of linguini, tossed in rosa sauce, with roasted tomatoes, garlic & herbs, finished with fresh mozzarella & julienne basil",
        price: 30,
        category: "entree selections"
    },
    {
        title: "VEGGIE CHOPPED SALAD",
        description:
            "dried cherries, spinach, romaine, avocado, fontina & red apple tossed in citrus vinaigrette & finished with toasted almonds & pomegranate seeds",
        price: 30,
        category: "entree selections"
    }
];
