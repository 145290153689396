

// Some dummy data to test layout

import { SearchResults } from "./QMenuSearchTest";

// Each menu dummy item should be from different cultural cuisines and the fields should be prefixed slighly differently
export const initialSearchResults: SearchResults = {
	"menu_items": [
		{
			"data": "{\"_id\": \"948257e1-63c2-4394-888b-c8f47270a9cf\", \"refProduct\": {\"_id\": {\"$oid\": \"63f472c1f6271c39b863d9f5\"}, \"name\": \"BBQ Ribs\", \"description\": \"V\\u00e5ra ber\\u00f6mda ribs serveras med coleslaw, corn on the cob & fries.\", \"defaultPrice\": 239.0, \"vatRate\": 12.0, \"companyId\": \"63e0d4707edc863ef8ba686a\", \"refProductCategoryId\": \"63f472c1f6271c39b863d9d8\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/63e0d4707edc863ef8ba686a/Gallery/medium/Ribs.jpg\", \"imageResizedUrls\": {\"large\": \"https://s3-eu-west-1.amazonaws.com/qopla/63e0d4707edc863ef8ba686a/Gallery/large/Ribs.jpg\", \"medium\": \"https://s3-eu-west-1.amazonaws.com/qopla/63e0d4707edc863ef8ba686a/Gallery/medium/Ribs.jpg\", \"small\": \"https://s3-eu-west-1.amazonaws.com/qopla/63e0d4707edc863ef8ba686a/Gallery/small/Ribs.jpg\"}, \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false, \"articleNumber\": null, \"ingredients\": null, \"modifications\": null}, \"price\": 259.0, \"sortOrder\": 0, \"color\": \"\", \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Våra berömda ribs serveras med coleslaw, corn on the cob & fries.",
			"distance": 0.16968965530395508,
			"menu_category_id": "bc8e18a7-8bd8-43f9-8458-86f051be014e",
			"menu_id": "63f478b481140075b18f00a0",
			"menu_item_id": "948257e1-63c2-4394-888b-c8f47270a9cf",
			"menu_item_text": "BBQ Ribs Våra berömda ribs serveras med coleslaw, corn on the cob & fries.",
			"menu_product_id": "948257e1-63c2-4394-888b-c8f47270a9cf",
			"name": "BBQ Ribs",
			"ref_product_id": "63f472c1f6271c39b863d9f5"
		},
		{
			"data": "{\"_id\": \"22c2a509-c003-4029-a04e-cd6b86e8e382\", \"refProduct\": {\"_id\": {\"$oid\": \"64f1a0a8df5f716029e2d341\"}, \"name\": \"BBQ Ribs\", \"description\": \"V\\u00e5ra ber\\u00f6mda ribs serveras med coleslaw, corn on the cob & fries.\", \"defaultPrice\": 229.0, \"vatRate\": 12.0, \"companyId\": \"64df589691444440efc405db\", \"refProductCategoryId\": \"64f1a0a8df5f716029e2d32e\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/64df589691444440efc405db/Gallery/medium/ribs2.jpg\", \"imageResizedUrls\": {\"small\": \"https://s3-eu-west-1.amazonaws.com/qopla/64df589691444440efc405db/Gallery/small/ribs2.jpg\", \"medium\": \"https://s3-eu-west-1.amazonaws.com/qopla/64df589691444440efc405db/Gallery/medium/ribs2.jpg\", \"large\": \"https://s3-eu-west-1.amazonaws.com/qopla/64df589691444440efc405db/Gallery/large/ribs2.jpg\"}, \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"price\": 285.0, \"sortOrder\": 0, \"color\": \"\", \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Våra berömda ribs serveras med coleslaw, corn on the cob & fries.",
			"distance": 0.16968965530395508,
			"menu_category_id": "a46f0d10-7567-4440-9dd2-6c9e885731e0",
			"menu_id": "64f1a0a8df5f716029e2d376",
			"menu_item_id": "22c2a509-c003-4029-a04e-cd6b86e8e382",
			"menu_item_text": "BBQ Ribs Våra berömda ribs serveras med coleslaw, corn on the cob & fries.",
			"menu_product_id": "22c2a509-c003-4029-a04e-cd6b86e8e382",
			"name": "BBQ Ribs",
			"ref_product_id": "64f1a0a8df5f716029e2d341"
		},
		{
			"data": "{\"_id\": \"21c8229f-d69c-4b8e-b005-632f627ffc13\", \"refProduct\": {\"_id\": {\"$oid\": \"62fa821d0cabf24330d9f315\"}, \"name\": \"BBQ Ribs\", \"description\": \"V\\u00e5ra ber\\u00f6mda ribs serveras med coleslaw, corn on the cob & fries.\", \"defaultPrice\": 145.0, \"vatRate\": 12.0, \"companyId\": \"62f1320baf417b6af63de1da\", \"refProductCategoryId\": \"62fa7b772cc80c2f99b8dc3d\", \"modifications\": {\"sizes\": [{\"name\": \"Halv\", \"price\": 0.0, \"addonPrice\": 0.0}, {\"name\": \"Hel\", \"price\": 0.0, \"addonPrice\": 64.0}], \"flavours\": [], \"types\": [], \"other\": []}, \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/medium/ribs2.jpg\", \"imageResizedUrls\": {\"small\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/small/ribs2.jpg\", \"medium\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/medium/ribs2.jpg\", \"large\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/large/ribs2.jpg\"}, \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"price\": 159.0, \"sortOrder\": 2, \"color\": \"\", \"modifications\": {\"sizes\": [{\"name\": \"Halv\", \"price\": 0.0, \"addonPrice\": 0.0}, {\"name\": \"Hel\", \"price\": 0.0, \"addonPrice\": 60.0}], \"flavours\": [], \"types\": [], \"other\": []}, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Våra berömda ribs serveras med coleslaw, corn on the cob & fries.",
			"distance": 0.16973865032196045,
			"menu_category_id": "0721f947-7530-4cd2-a041-35730c2b5989",
			"menu_id": "62fa80db0cabf24330d9f2d2",
			"menu_item_id": "21c8229f-d69c-4b8e-b005-632f627ffc13",
			"menu_item_text": "BBQ Ribs Våra berömda ribs serveras med coleslaw, corn on the cob & fries.",
			"menu_product_id": "21c8229f-d69c-4b8e-b005-632f627ffc13",
			"name": "BBQ Ribs",
			"ref_product_id": "62fa821d0cabf24330d9f315"
		},
		{
			"data": "{\"_id\": \"e8fab3a2-bc39-4bc2-99cb-409a61b8581f\", \"refProduct\": {\"_id\": {\"$oid\": \"64e8bc3bd895456aec695029\"}, \"name\": \"Panini Kyckling BBQ\", \"description\": \"\", \"defaultPrice\": 69.0, \"vatRate\": 12.0, \"companyId\": \"64e850739f7757559f54a190\", \"refProductCategoryId\": \"64e8bb1e0dd60563025c14f7\", \"articleNumber\": 0, \"imageUrl\": \"\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 7, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "",
			"distance": 0.17592990398406982,
			"menu_category_id": "1fb42039-1373-4510-b31c-f7d4c78de945",
			"menu_id": "64ec5d665af7f12c4c6a92ba",
			"menu_item_id": "e8fab3a2-bc39-4bc2-99cb-409a61b8581f",
			"menu_item_text": "Panini Kyckling BBQ ",
			"menu_product_id": "e8fab3a2-bc39-4bc2-99cb-409a61b8581f",
			"name": "Panini Kyckling BBQ",
			"ref_product_id": "64e8bc3bd895456aec695029"
		},
		{
			"data": "{\"_id\": \"1aa7bb4d-a7bf-4228-8cb7-9ed011bbb906\", \"refProduct\": {\"_id\": {\"$oid\": \"6523fb0820e7cf42c0e552bb\"}, \"name\": \"The Texas Trinity (f\\u00f6r 2 personer)\", \"description\": \"Oxfil\\u00e9, ryggbiff, BBQ ribs & smokey BBQ jalape\\u00f1okorv.  med crispy onion, corn on the cob, coleslaw, Texas style bearnaise & mac n cheese.\", \"defaultPrice\": 749.0, \"vatRate\": 12.0, \"companyId\": \"63e0d4707edc863ef8ba686a\", \"refProductCategoryId\": \"63f472c1f6271c39b863d9d8\", \"imageUrl\": \"\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 2, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Oxfilé, ryggbiff, BBQ ribs & smokey BBQ jalapeñokorv.  med crispy onion, corn on the cob, coleslaw, Texas style bearnaise & mac n cheese.",
			"distance": 0.1772328019142151,
			"menu_category_id": "bc8e18a7-8bd8-43f9-8458-86f051be014e",
			"menu_id": "63f478b481140075b18f00a0",
			"menu_item_id": "1aa7bb4d-a7bf-4228-8cb7-9ed011bbb906",
			"menu_item_text": "The Texas Trinity (för 2 personer) Oxfilé, ryggbiff, BBQ ribs & smokey BBQ jalapeñokorv.  med crispy onion, corn on the cob, coleslaw, Texas style bearnaise & mac n cheese.",
			"menu_product_id": "1aa7bb4d-a7bf-4228-8cb7-9ed011bbb906",
			"name": "The Texas Trinity (för 2 personer)",
			"ref_product_id": "6523fb0820e7cf42c0e552bb"
		},
		{
			"data": "{\"_id\": \"89e31e26-60b9-4f92-be56-bb5fabbfa13a\", \"refProduct\": {\"_id\": {\"$oid\": \"63ab15ec66d096742d2f8fc8\"}, \"name\": \"BBQ S\\u00e5s\", \"description\": \"\", \"defaultPrice\": 15.0, \"vatRate\": 12.0, \"companyId\": \"639c658e56735c63b75bb714\", \"refProductCategoryId\": \"63ab15cd658f012050ca2d37\", \"articleNumber\": 0, \"imageUrl\": \"\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 1, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "",
			"distance": 0.1804184913635254,
			"menu_category_id": "fd705c14-b83c-4db8-9af1-22dfe56f9f65",
			"menu_id": "63ac0ee3333e0e16ffe1d7e1",
			"menu_item_id": "89e31e26-60b9-4f92-be56-bb5fabbfa13a",
			"menu_item_text": "BBQ Sås ",
			"menu_product_id": "89e31e26-60b9-4f92-be56-bb5fabbfa13a",
			"name": "BBQ Sås",
			"ref_product_id": "63ab15ec66d096742d2f8fc8"
		},
		{
			"data": "{\"_id\": \"47a4e18d-600a-4cf9-adf4-f9fef270b80c\", \"refProduct\": {\"_id\": {\"$oid\": \"6435a4c36b9122170805ba50\"}, \"name\": \"Texas BBQ Burger\", \"description\": \"Bacon, cheddarost, BBQ-s\\u00e5s, mayo, sallad & silverl\\u00f6k.  Serveras med fries. \", \"defaultPrice\": 119.0, \"vatRate\": 12.0, \"companyId\": \"62f1320baf417b6af63de1da\", \"refProductCategoryId\": \"62fa7b772cc80c2f99b8dc3d\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/medium/bbq.png\", \"imageResizedUrls\": {\"small\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/small/bbq.png\", \"medium\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/medium/bbq.png\", \"large\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/large/bbq.png\"}, \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"price\": 129.0, \"sortOrder\": 3, \"color\": \"\", \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Bacon, cheddarost, BBQ-sås, mayo, sallad & silverlök.  Serveras med fries. ",
			"distance": 0.1813901662826538,
			"menu_category_id": "2923e781-9930-4da2-8a39-1aac0844b426",
			"menu_id": "62fa80db0cabf24330d9f2d2",
			"menu_item_id": "47a4e18d-600a-4cf9-adf4-f9fef270b80c",
			"menu_item_text": "Texas BBQ Burger Bacon, cheddarost, BBQ-sås, mayo, sallad & silverlök.  Serveras med fries. ",
			"menu_product_id": "47a4e18d-600a-4cf9-adf4-f9fef270b80c",
			"name": "Texas BBQ Burger",
			"ref_product_id": "6435a4c36b9122170805ba50"
		},
		{
			"data": "{\"_id\": \"c76b6dfe-4ef2-460e-b50b-eb817ae46ec7\", \"refProduct\": {\"_id\": {\"$oid\": \"5e9998f97ce05107f4ff2025\"}, \"name\": \"Satey Gai\", \"description\": \"Curry marinerade grillade kycklingspett, serveras med jordn\\u00f6tss\\u00e5s\", \"defaultPrice\": 115.0, \"vatRate\": 12.0, \"companyId\": \"5e8f893024a638278e087b7f\", \"refProductCategoryId\": \"5e9998de3b5b80535d7d8443\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/5e8f893024a638278e087b7f/Gallery/24._Satey_gay.jpg\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 0, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Curry marinerade grillade kycklingspett, serveras med jordnötssås",
			"distance": 0.1836572289466858,
			"menu_category_id": "72b41ae3-7c0b-48a8-9998-dd410be905d4",
			"menu_id": "5ea2a213b9dc8e280c7e34d2",
			"menu_item_id": "c76b6dfe-4ef2-460e-b50b-eb817ae46ec7",
			"menu_item_text": "Satey Gai Curry marinerade grillade kycklingspett, serveras med jordnötssås",
			"menu_product_id": "c76b6dfe-4ef2-460e-b50b-eb817ae46ec7",
			"name": "Satey Gai",
			"ref_product_id": "5e9998f97ce05107f4ff2025"
		},
		{
			"data": "{\"_id\": \"6fae459e-e915-4d35-adac-482e485ac3ba\", \"refProduct\": {\"_id\": {\"$oid\": \"5e9998f97ce05107f4ff2025\"}, \"name\": \"Satey Gai\", \"description\": \"Curry marinerade grillade kycklingspett, serveras med jordn\\u00f6tss\\u00e5s\", \"defaultPrice\": 115.0, \"vatRate\": 12.0, \"companyId\": \"5e8f893024a638278e087b7f\", \"refProductCategoryId\": \"5e9998de3b5b80535d7d8443\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/5e8f893024a638278e087b7f/Gallery/24._Satey_gay.jpg\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 0, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Curry marinerade grillade kycklingspett, serveras med jordnötssås",
			"distance": 0.18377596139907837,
			"menu_category_id": "f5afa71a-5a85-4c70-8e24-e47d4072066e",
			"menu_id": "5ea2a5fe3b01042968482800",
			"menu_item_id": "6fae459e-e915-4d35-adac-482e485ac3ba",
			"menu_item_text": "Satey Gai Curry marinerade grillade kycklingspett, serveras med jordnötssås",
			"menu_product_id": "6fae459e-e915-4d35-adac-482e485ac3ba",
			"name": "Satey Gai",
			"ref_product_id": "5e9998f97ce05107f4ff2025"
		},

		{
			"data": "{\"_id\": \"fe4ea27d-b7ce-4123-a333-7cc05341d7cb\", \"refProduct\": {\"_id\": {\"$oid\": \"6315c2b96a823b0e242f5266\"}, \"name\": \"Kyckling Bashanti\", \"description\": \"strimlad grillad kycklingfil\\u00e9 med stekt l\\u00f6k, paprika, chilipickles och baltis\\u00e5s. Med \\u00bd naan + Mango lassi+ Soppa + kaffe\", \"defaultPrice\": 115.0, \"vatRate\": 12.0, \"companyId\": \"62ea368ebdf56e1aca4c4e13\", \"refProductCategoryId\": \"6315c2284b23b07dcd6bdeba\", \"articleNumber\": 0, \"imageUrl\": \"\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 1, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "strimlad grillad kycklingfilé med stekt lök, paprika, chilipickles och baltisås. Med ½ naan + Mango lassi+ Soppa + kaffe",
			"distance": 0.18647527694702148,
			"menu_category_id": "0ead8413-a326-4ae0-a15a-c8be3945bc3d",
			"menu_id": "6315c53cf59bfd14dbb907c1",
			"menu_item_id": "fe4ea27d-b7ce-4123-a333-7cc05341d7cb",
			"menu_item_text": "Kyckling Bashanti strimlad grillad kycklingfilé med stekt lök, paprika, chilipickles och baltisås. Med ½ naan + Mango lassi+ Soppa + kaffe",
			"menu_product_id": "fe4ea27d-b7ce-4123-a333-7cc05341d7cb",
			"name": "Kyckling Bashanti",
			"ref_product_id": "6315c2b96a823b0e242f5266"
		},
		{
			"data": "{\"_id\": \"819224e3-d316-4031-b4bf-f3aff5df31fb\", \"refBundleProduct\": {\"_id\": {\"$oid\": \"61112979cbaba37b85134c05\"}, \"name\": \"Yorker Burger Meny\", \"description\": \"H\\u00e4ngm\\u00f6rat svenskt n\\u00f6tk\\u00f6tt, Briochebr\\u00f6d, Cheddarost, Jalape\\u00f1omajo, Yorks egna BBQ-S\\u00e5s och R\\u00f6dl\\u00f6k. Inkl fries & dricka \", \"defaultPrice\": 119.0, \"vatRate\": 12.0, \"companyId\": \"60b66212340be4670461fa82\", \"refProductCategoryId\": \"60bdd9b0151b80725063974e\", \"articleNumber\": 0, \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/60b66212340be4670461fa82/Gallery/yorker.jpg\", \"priceType\": \"PRICE_PER_UNIT\", \"bundleProductCategories\": [{\"_id\": \"72d253f6-dad9-4f7c-af34-d5f8a686ccdb\", \"limit\": 1, \"name\": \"V\\u00e4lj en storlek\", \"kdsUnitDisplayName\": \"\", \"refProductIdList\": [\"60b9f8538a69f0381f37c937\", \"60bdd5f3159a434b292ae914\", \"6256d5d0db26a11c0859b433\"], \"sortOrder\": 0}, {\"_id\": \"cd0a8c41-3375-41f9-abec-0b4574a2a44e\", \"limit\": 1, \"name\": \"V\\u00e4lj pommes\", \"kdsUnitDisplayName\": \"\", \"refProductIdList\": [\"60bdd7a9151b80725063973a\", \"61727a2f407c0341155f3b6a\", \"617a4220ff0182655de47f09\", \"6315aad03d4af65685eabdd7\"], \"sortOrder\": 0}, {\"_id\": \"a0fd9d2a-1e6f-4e09-849f-a44231c4a42d\", \"limit\": 1, \"name\": \"V\\u00e4lj en dryck\", \"kdsUnitDisplayName\": \"\", \"refProductIdList\": [\"60bdd8bf151b807250639743\", \"60bdd8c2f012d316b1a07ff8\", \"60bdd8ca8a69f0381f37f0ae\", \"60bdd8cf3117397ec8a338ea\", \"60bdd8d4151b807250639744\", \"60dc5e87ebdd9226850e4804\", \"6242f2c873d8df011d1868a0\", \"624300f6eeede42eaecd65cb\", \"62820aaa27b0f146e06c6c0d\"], \"sortOrder\": 0}], \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 6, \"bundleCategoryColors\": [], \"menuBundleModifications\": [], \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Hängmörat svenskt nötkött, Briochebröd, Cheddarost, Jalapeñomajo, Yorks egna BBQ-Sås och Rödlök. Inkl fries & dricka ",
			"distance": 0.1869802474975586,
			"menu_category_id": "4cb25982-2b7c-492c-8dce-b2211d0226eb",
			"menu_id": "63b3f4db09cf184a9664f1ea",
			"menu_item_id": "819224e3-d316-4031-b4bf-f3aff5df31fb",
			"menu_item_text": "Yorker Burger Meny Hängmörat svenskt nötkött, Briochebröd, Cheddarost, Jalapeñomajo, Yorks egna BBQ-Sås och Rödlök. Inkl fries & dricka ",
			"menu_product_id": "819224e3-d316-4031-b4bf-f3aff5df31fb",
			"name": "Yorker Burger Meny",
			"ref_product_id": "61112979cbaba37b85134c05"
		},
		{
			"data": "{\"_id\": \"dc86c205-4546-4eb7-851c-00faaea8be1b\", \"refProduct\": {\"_id\": {\"$oid\": \"5f9829b7e6e7282b158024aa\"}, \"name\": \"3. Gai satay\", \"description\": \"Marinerad grillspett och 5 stycken kycklingfil\\u00e9er. Serveras med jordn\\u00f6tss\\u00e5s och ris\", \"defaultPrice\": 110.0, \"vatRate\": 12.0, \"companyId\": \"5ef5e6d52adc2214783d143c\", \"refProductCategoryId\": \"5f982904bb76380b7533b463\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/5ef5e6d52adc2214783d143c/Gallery/3.jpg\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 4, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Marinerad grillspett och 5 stycken kycklingfiléer. Serveras med jordnötssås och ris",
			"distance": 0.18757683038711548,
			"menu_category_id": "7c3797f8-ebc4-415f-8dc3-830d4742d971",
			"menu_id": "5fbbbf8270c5603f6d5ee206",
			"menu_item_id": "dc86c205-4546-4eb7-851c-00faaea8be1b",
			"menu_item_text": "3. Gai satay Marinerad grillspett och 5 stycken kycklingfiléer. Serveras med jordnötssås och ris",
			"menu_product_id": "dc86c205-4546-4eb7-851c-00faaea8be1b",
			"name": "3. Gai satay",
			"ref_product_id": "5f9829b7e6e7282b158024aa"
		},

		{
			"data": "{\"_id\": \"11714d23-200f-4319-a829-04fcd61b9d86\", \"refProduct\": {\"_id\": {\"$oid\": \"5ea1546aeb7d087efc0a20b3\"}, \"name\": \"Satey Gai\", \"description\": \"Curry marinerade grillade kycklingspett, serveras med jordn\\u00f6tss\\u00e5s.\", \"defaultPrice\": 102.0, \"vatRate\": 12.0, \"companyId\": \"5e8f893024a638278e087b7f\", \"refProductCategoryId\": \"5ea14b96f4e4a85cfc238809\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/5e8f893024a638278e087b7f/Gallery/24._Satey_gay.jpg\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"price\": 105.0, \"sortOrder\": 12, \"color\": \"\", \"activeHours\": [{\"dayOfWeek\": \"WEDNESDAY\", \"holiday\": false, \"closed\": false}], \"disabled\": false, \"deleted\": false}",
			"description": "Curry marinerade grillade kycklingspett, serveras med jordnötssås.",
			"distance": 0.18868327140808105,
			"menu_category_id": "7c516a78-2c59-4802-9bdd-b254c5a1a49b",
			"menu_id": "5ea2a213b9dc8e280c7e34d2",
			"menu_item_id": "11714d23-200f-4319-a829-04fcd61b9d86",
			"menu_item_text": "Satey Gai Curry marinerade grillade kycklingspett, serveras med jordnötssås.",
			"menu_product_id": "11714d23-200f-4319-a829-04fcd61b9d86",
			"name": "Satey Gai",
			"ref_product_id": "5ea1546aeb7d087efc0a20b3"
		},
		{
			"data": "{\"_id\": \"ca285f80-cde6-4d26-ab84-d89815be11e7\", \"refProduct\": {\"_id\": {\"$oid\": \"5f915060a8e64a3d55e0937c\"}, \"name\": \"Big asian griller\", \"description\": \"120g Gatuk\\u00f6k-korv Korv med picklad r\\u00f6dl\\u00f6k, kimchirostade sesamfr\\u00f6n, Rostad l\\u00f6k, BBQ s\\u00e5s\", \"defaultPrice\": 89.0, \"vatRate\": 12.0, \"companyId\": \"5f9147dda8e64a3d55e0603c\", \"refProductCategoryId\": \"5f914aceb7de4c11d50884ff\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/5f9147dda8e64a3d55e0603c/Gallery/BigAsian.jpg\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 2, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "120g Gatukök-korv Korv med picklad rödlök, kimchirostade sesamfrön, Rostad lök, BBQ sås",
			"distance": 0.19044750928878784,
			"menu_category_id": "dc10f909-1b96-457c-88a8-3129004fb4ea",
			"menu_id": "6000358a41148b452c0c65fa",
			"menu_item_id": "ca285f80-cde6-4d26-ab84-d89815be11e7",
			"menu_item_text": "Big asian griller 120g Gatukök-korv Korv med picklad rödlök, kimchirostade sesamfrön, Rostad lök, BBQ sås",
			"menu_product_id": "ca285f80-cde6-4d26-ab84-d89815be11e7",
			"name": "Big asian griller",
			"ref_product_id": "5f915060a8e64a3d55e0937c"
		},
		{
			"data": "{\"_id\": \"89dee57a-2168-4dac-a03a-cc556ee30563\", \"refProduct\": {\"_id\": {\"$oid\": \"6331cb70ddf0f7720b29ed61\"}, \"name\": \"Smokehouse Burger\", \"description\": \"Burgare med cheddarost, majonn\\u00e4s, l\\u00f6k, sallad, bacon & steak sauce. Serveras med coleslaw, pickles & fries.\", \"defaultPrice\": 163.0, \"vatRate\": 12.0, \"companyId\": \"63319458fc4328771574cea0\", \"refProductCategoryId\": \"6331ae0018e6576281b42935\", \"articleNumber\": 0, \"imageUrl\": \"\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 3, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Burgare med cheddarost, majonnäs, lök, sallad, bacon & steak sauce. Serveras med coleslaw, pickles & fries.",
			"distance": 0.1905118227005005,
			"menu_category_id": "6fea39d3-3f3d-4320-bf6c-4efcc10f0b56",
			"menu_id": "63333ce43d20be6a443cab3e",
			"menu_item_id": "89dee57a-2168-4dac-a03a-cc556ee30563",
			"menu_item_text": "Smokehouse Burger Burgare med cheddarost, majonnäs, lök, sallad, bacon & steak sauce. Serveras med coleslaw, pickles & fries.",
			"menu_product_id": "89dee57a-2168-4dac-a03a-cc556ee30563",
			"name": "Smokehouse Burger",
			"ref_product_id": "6331cb70ddf0f7720b29ed61"
		},

		{
			"data": "{\"_id\": \"65be05cd-18a6-4494-897e-884b6d5a7b40\", \"refProduct\": {\"_id\": {\"$oid\": \"6373754a1ec20e613db007d5\"}, \"name\": \"Grilled Tuna Salad\", \"description\": \"Sallad med grillad tonfisk, austin jalape\\u00f1o majonn\\u00e4s, koriander och crispy onion.\", \"defaultPrice\": 219.0, \"vatRate\": 12.0, \"companyId\": \"62f1320baf417b6af63de1da\", \"refProductCategoryId\": \"637375491ec20e613db007ca\", \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/medium/tuna_salad.JPG\", \"imageResizedUrls\": {\"small\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/small/tuna_salad.JPG\", \"medium\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/medium/tuna_salad.JPG\", \"large\": \"https://s3-eu-west-1.amazonaws.com/qopla/62f1320baf417b6af63de1da/Gallery/large/tuna_salad.JPG\"}, \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"price\": 169.0, \"sortOrder\": 3, \"color\": \"\", \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Sallad med grillad tonfisk, austin jalapeño majonnäs, koriander och crispy onion.",
			"distance": 0.20327520370483398,
			"menu_category_id": "841a0ff7-4918-4b39-bdbe-62fedebcde21",
			"menu_id": "62fa80db0cabf24330d9f2d2",
			"menu_item_id": "65be05cd-18a6-4494-897e-884b6d5a7b40",
			"menu_item_text": "Grilled Tuna Salad Sallad med grillad tonfisk, austin jalapeño majonnäs, koriander och crispy onion.",
			"menu_product_id": "65be05cd-18a6-4494-897e-884b6d5a7b40",
			"name": "Grilled Tuna Salad",
			"ref_product_id": "6373754a1ec20e613db007d5"
		},
		{
			"data": "{\"_id\": \"8c5b4673-e25f-4455-b586-2057443186bf\", \"refProduct\": {\"_id\": {\"$oid\": \"5f64c3e3af459e07954d341d\"}, \"name\": \"California deluxe 10 bitar\", \"description\": \"Avokado, gurka och krabbr\\u00f6ra som \\u00e4r toppat med grillad lax & 2 speciella s\\u00e5ser\", \"defaultPrice\": 179.0, \"vatRate\": 12.0, \"companyId\": \"5f649d0fd2e93f3e37818f87\", \"refProductCategoryId\": \"5f64c3aadfaeab621bda9d20\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"price\": 219.0, \"sortOrder\": 1, \"color\": \"\", \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Avokado, gurka och krabbröra som är toppat med grillad lax & 2 speciella såser",
			"distance": 0.20336192846298218,
			"menu_category_id": "5ec481ea-f52c-410a-bbbd-e9a0c825f555",
			"menu_id": "6290e9da43687500b990d5d3",
			"menu_item_id": "8c5b4673-e25f-4455-b586-2057443186bf",
			"menu_item_text": "California deluxe 10 bitar Avokado, gurka och krabbröra som är toppat med grillad lax & 2 speciella såser",
			"menu_product_id": "8c5b4673-e25f-4455-b586-2057443186bf",
			"name": "California deluxe 10 bitar",
			"ref_product_id": "5f64c3e3af459e07954d341d"
		},
		{
			"data": "{\"_id\": \"8364520b-a691-4a1f-a526-a1981e1c418d\", \"refProduct\": {\"_id\": {\"$oid\": \"5fdcc2526accca302b606013\"}, \"name\": \"8. Kyckling Tikka\", \"description\": \"Kol grillad yogurt & kryddmarinerade kycklingfil\\u00e9 bitar p\\u00e5 spett. Serveras med sallad och s\\u00e5s.\", \"defaultPrice\": 60.0, \"vatRate\": 12.0, \"companyId\": \"5fd0c55e339c696b740ee0d5\", \"refProductCategoryId\": \"5fdcbd318cc3035bb5fe93db\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 7, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Kol grillad yogurt & kryddmarinerade kycklingfilé bitar på spett. Serveras med sallad och sås.",
			"distance": 0.2033919095993042,
			"menu_category_id": "b394b50c-e5e9-4d3b-bbd7-95fe3365533a",
			"menu_id": "5fe0d9502e30c8558cb0286b",
			"menu_item_id": "8364520b-a691-4a1f-a526-a1981e1c418d",
			"menu_item_text": "8. Kyckling Tikka Kol grillad yogurt & kryddmarinerade kycklingfilé bitar på spett. Serveras med sallad och sås.",
			"menu_product_id": "8364520b-a691-4a1f-a526-a1981e1c418d",
			"name": "8. Kyckling Tikka",
			"ref_product_id": "5fdcc2526accca302b606013"
		},
		{
			"data": "{\"_id\": \"786060dc-20b1-412f-a1f4-193a29497ed0\", \"refProduct\": {\"_id\": {\"$oid\": \"65268d28dd5682145c3caad7\"}, \"name\": \"Turkey Burger\", \"description\": \"Kalkonburgare med cheddarost, bacon, brynt sm\\u00f6r & apple butter. Serveras med coleslaw och pommes\", \"defaultPrice\": 229.0, \"vatRate\": 12.0, \"companyId\": \"63e0d4707edc863ef8ba686a\", \"refProductCategoryId\": \"63f472c1f6271c39b863d9d2\", \"articleNumber\": 0, \"imageUrl\": \"\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 0, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Kalkonburgare med cheddarost, bacon, brynt smör & apple butter. Serveras med coleslaw och pommes",
			"distance": 0.2034388780593872,
			"menu_category_id": "da3491a9-dc23-4b80-b3fc-90993ac72e22",
			"menu_id": "63f478b481140075b18f00a0",
			"menu_item_id": "786060dc-20b1-412f-a1f4-193a29497ed0",
			"menu_item_text": "Turkey Burger Kalkonburgare med cheddarost, bacon, brynt smör & apple butter. Serveras med coleslaw och pommes",
			"menu_product_id": "786060dc-20b1-412f-a1f4-193a29497ed0",
			"name": "Turkey Burger",
			"ref_product_id": "65268d28dd5682145c3caad7"
		},
		{
			"data": "{\"_id\": \"97182d21-f016-40b2-8110-c4399fd72d84\", \"refProduct\": {\"_id\": {\"$oid\": \"65d87d51095d9d40798168e1\"}, \"name\": \"Bibimbap med Yakiniku\", \"description\": \"\", \"defaultPrice\": 168.0, \"vatRate\": 12.0, \"companyId\": \"651185e50e7a6a700917ae3e\", \"refProductCategoryId\": \"657c1211faa9d85fbcd8b75e\", \"articleNumber\": 0, \"imageUrl\": \"\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 8, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "",
			"distance": 0.20367270708084106,
			"menu_category_id": "ce36308a-f3e7-43d5-910f-efc9dea7b350",
			"menu_id": "6554ba15e7830e1d9c89662a",
			"menu_item_id": "97182d21-f016-40b2-8110-c4399fd72d84",
			"menu_item_text": "Bibimbap med Yakiniku ",
			"menu_product_id": "97182d21-f016-40b2-8110-c4399fd72d84",
			"name": "Bibimbap med Yakiniku",
			"ref_product_id": "65d87d51095d9d40798168e1"
		},
		{
			"data": "{\"_id\": \"6131d10a-7af9-45a5-90d6-345626ddbe62\", \"refProduct\": {\"_id\": {\"$oid\": \"6051c06903dbf3781f039cc3\"}, \"name\": \"Chili diablo burger\", \"description\": \"No bean chili, crispy lettuce, raw silver onion, jalapenos, sour cream & Hot n Hot. We like Sloppy Joe-style!\", \"defaultPrice\": 149.0, \"vatRate\": 12.0, \"companyId\": \"6051bcfa7ac90a1b7fafebfe\", \"refProductCategoryId\": \"6051bff803dbf3781f039cbf\", \"priceType\": \"PRICE_PER_UNIT\", \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 7, \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "No bean chili, crispy lettuce, raw silver onion, jalapenos, sour cream & Hot n Hot. We like Sloppy Joe-style!",
			"distance": 0.20402765274047852,
			"menu_category_id": "a7285ab3-6c1e-4ff5-9942-96e278d74910",
			"menu_id": "6051f82ee1afc410940235ca",
			"menu_item_id": "6131d10a-7af9-45a5-90d6-345626ddbe62",
			"menu_item_text": "Chili diablo burger No bean chili, crispy lettuce, raw silver onion, jalapenos, sour cream & Hot n Hot. We like Sloppy Joe-style!",
			"menu_product_id": "6131d10a-7af9-45a5-90d6-345626ddbe62",
			"name": "Chili diablo burger",
			"ref_product_id": "6051c06903dbf3781f039cc3"
		},
		{
			"data": "{\"_id\": \"d29fe29d-8e3a-4838-980e-8fc069f07357\", \"refBundleProduct\": {\"_id\": {\"$oid\": \"63fe578d19b88d1ff287e74d\"}, \"name\": \"Isot Special\", \"description\": \"Grilltallrik med adana, kycklingspett, k\\u00f6fte, lammkotlett, shish kebab, tzatziki, ezme och sallad. V\\u00e4lj tillbeh\\u00f6r i n\\u00e4sta steg.\", \"defaultPrice\": 305.0, \"vatRate\": 12.0, \"companyId\": \"63f72e80da91b11ad34f3951\", \"refProductCategoryId\": \"63fe5663bbe2c977a59048ac\", \"articleNumber\": 0, \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/63f72e80da91b11ad34f3951/Gallery/medium/stockholm_isot_special.jpg\", \"imageResizedUrls\": {\"small\": \"https://s3-eu-west-1.amazonaws.com/qopla/63f72e80da91b11ad34f3951/Gallery/small/stockholm_isot_special.jpg\", \"medium\": \"https://s3-eu-west-1.amazonaws.com/qopla/63f72e80da91b11ad34f3951/Gallery/medium/stockholm_isot_special.jpg\", \"large\": \"https://s3-eu-west-1.amazonaws.com/qopla/63f72e80da91b11ad34f3951/Gallery/large/stockholm_isot_special.jpg\"}, \"priceType\": \"PRICE_PER_UNIT\", \"bundleProductCategories\": [{\"_id\": \"815d515d-cac6-4832-b535-f5e179041bca\", \"limit\": 1, \"name\": \"V\\u00e4lj Tillbeh\\u00f6r\", \"kdsUnitDisplayName\": \"\", \"refProductIdList\": [\"63fe564e5542d523a20e38ca\", \"63fe56567997f042311e355d\", \"63fe5656bbe2c977a59048a9\"], \"sortOrder\": 0}], \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 9, \"bundleCategoryColors\": [], \"menuBundleModifications\": [], \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Grilltallrik med adana, kycklingspett, köfte, lammkotlett, shish kebab, tzatziki, ezme och sallad. Välj tillbehör i nästa steg.",
			"distance": 0.20409774780273438,
			"menu_category_id": "b725713e-ce1c-476f-b468-cca921df2d58",
			"menu_id": "6579d5c625868a285046e8a1",
			"menu_item_id": "d29fe29d-8e3a-4838-980e-8fc069f07357",
			"menu_item_text": "Isot Special Grilltallrik med adana, kycklingspett, köfte, lammkotlett, shish kebab, tzatziki, ezme och sallad. Välj tillbehör i nästa steg.",
			"menu_product_id": "d29fe29d-8e3a-4838-980e-8fc069f07357",
			"name": "Isot Special",
			"ref_product_id": "63fe578d19b88d1ff287e74d"
		},
		{
			"data": "{\"_id\": \"1201b068-17b3-4883-a3fe-a913c1925a10\", \"refBundleProduct\": {\"_id\": {\"$oid\": \"65eac44cd3d83428f88e347f\"}, \"name\": \"Varm Salmon\", \"description\": \"Citron pasta m. spenat. Serveras med grillat vitl\\u00f6ksbr\\u00f6d.\", \"defaultPrice\": 169.0, \"vatRate\": 12.0, \"companyId\": \"65c20469cf36b446804be9d8\", \"refProductCategoryId\": \"65cb70d50c89533c4a2b9452\", \"articleNumber\": 0, \"imageUrl\": \"https://s3-eu-west-1.amazonaws.com/qopla/65c20469cf36b446804be9d8/Gallery/medium/Varmpasta-1.png\", \"imageResizedUrls\": {\"small\": \"https://s3-eu-west-1.amazonaws.com/qopla/65c20469cf36b446804be9d8/Gallery/small/Varmpasta-1.png\", \"medium\": \"https://s3-eu-west-1.amazonaws.com/qopla/65c20469cf36b446804be9d8/Gallery/medium/Varmpasta-1.png\", \"large\": \"https://s3-eu-west-1.amazonaws.com/qopla/65c20469cf36b446804be9d8/Gallery/large/Varmpasta-1.png\"}, \"priceType\": \"PRICE_PER_UNIT\", \"bundleProductCategories\": [{\"_id\": \"efa1ffb8-e08d-443f-a710-e2a598061831\", \"limit\": 1, \"name\": \"Dryck?\", \"kdsUnitDisplayName\": \"\", \"refProductIdList\": [\"65cb6eccd5465a3f650b7231\", \"65cb6ed2d5465a3f650b7234\", \"65cb6ed2feec6e2657f324af\", \"65cb6ed214146f653a7b1972\", \"65cb6ed236973a305144063a\", \"65cb6ed236973a305144063c\", \"65cb72b736973a3051440769\"], \"sortOrder\": 0}], \"allergens\": [], \"disabled\": false, \"deleted\": false}, \"sortOrder\": 4, \"bundleCategoryColors\": [], \"menuBundleModifications\": [], \"activeHours\": [], \"disabled\": false, \"deleted\": false}",
			"description": "Citron pasta m. spenat. Serveras med grillat vitlöksbröd.",
			"distance": 0.20421922206878662,
			"menu_category_id": "69eefcb0-9cb3-42b3-88ef-2b9a2806352c",
			"menu_id": "65eac03ef6bf793bf1011313",
			"menu_item_id": "1201b068-17b3-4883-a3fe-a913c1925a10",
			"menu_item_text": "Varm Salmon Citron pasta m. spenat. Serveras med grillat vitlöksbröd.",
			"menu_product_id": "1201b068-17b3-4883-a3fe-a913c1925a10",
			"name": "Varm Salmon",
			"ref_product_id": "65eac44cd3d83428f88e347f"
		}
	]
}