/**
 * NOTE: This file is a WIP
 * This will likely become an extension of the Upload feature which will show the user an analysis of the menu they uploaded
 */
import React from "react";
import { FaLongArrowAltRight } from "@react-icons/all-files/fa/FaLongArrowAltRight";

import { Box, Flex, Header, Stack, BaseBoxProps, NewButton, NewGrid } from "Atoms";
import { ConsumerFooter } from "Organisms";

const shop = {
    name: "test name",
    imageUrl: undefined,
    contactInformation: {
        city: "test city",
        addressLine: "test address"
    },
    settings: {
        onlineSettings: {}
    }
};

/*
 * This should look like
 *  ⭐ Most Popular Dish
 *     New York Style Burger => 🪄XX (blurred) cheaper than the competition
 */
type Props = {
    analysisTitle: string;

    menuItemIcon: string;
    menuItemTitle: string;

    insightItemIcon?: string;
    // Needs to be a component as well in order to be able to render the blurred text
    insightItem: string | React.ReactNode;

    isPositive: boolean; // Controls if green or red
};
const insightColor = "#5094D3";
const menuColor = "#369048";

const QMenuAnalysisTitle = ({ children, ...props }: BaseBoxProps) => {
    return (
        <Box fontSize="1.5rem" fontWeight="bold" {...props}>
            {children}
        </Box>
    );
};

const QMenuAnalysisResultRow = (props: Props) => {
    const { analysisTitle, menuItemIcon, menuItemTitle, insightItemIcon, insightItem, isPositive } = props;

    const fontColor = isPositive ? "green.500" : "red.500";

    return (
        <Stack p={2} h="auto" stretch={2} borderBottom={"inset"}>
            <Box
                fontSize="1.25rem"
                fontWeight="bold"
                // color={fontColor}
                color={insightColor}
            >
                {analysisTitle}
            </Box>
            {/* <Flex
				justifyContent={"space-between"}
				m={4}
				mt={0}
			>
				<Box as="p">{menuItemIcon} {menuItemTitle}</Box>
				<Box as="p"
					color={fontColor}
				>{`--------->`}</Box>
				<Box as="p"
					color={fontColor}
				>{insightItemIcon} {insightItem}</Box>
			</Flex> */}

            <NewGrid templateColumns="2fr 1fr 3fr" p={2} mb={1}>
                <Box as="p" fontWeight="bold">
                    {menuItemIcon} {menuItemTitle}
                </Box>
                <Box as="p" textAlign={"center"} color={fontColor}>
                    <FaLongArrowAltRight />
                </Box>
                {/* This text should be blurred to be unreadable */}
                <Box
                    as="p"
                    textAlign={"right"}
                    color={fontColor}
                    // color={insightColor}
                >
                    {insightItemIcon} {insightItem}
                </Box>
            </NewGrid>
        </Stack>
    );
};

export const QMenuAnalysisResults = () => {
    const props = {
        restaurant: shop as any,
        addressInfo: "test address",
        headerMsg: {
            id: "test id",
            message: "test message",
            createdAt: "test createdAt",
            updatedAt: "test updatedAt",
            __typename: "test __typename"
        },
        systemMessageToast: () => {},
        table: null
    };

    const BlurredSpan = ({ children }: { children: string }) => {
        return (
            <Box filter={"blur(5px)"} display="inline">
                {children}
            </Box>
        );
    };
    const Span = ({ children }: { children: React.ReactNode }) => {
        return <Box display="inline">{children}</Box>;
    };

    const testRows = [
        {
            analysisTitle: "⭐ Most Popular Dish",
            menuItemIcon: "",
            menuItemTitle: "New York Style Pizza",
            insightItemIcon: "🪄",
            insightItem: (
                <Span>
                    <BlurredSpan>XX</BlurredSpan>% cheaper than competitors{" "}
                </Span>
            ),
            isPositive: false
        },
        {
            analysisTitle: "✨Best Title",
            menuItemIcon: "",
            menuItemTitle: "Charcoal Burger",
            insightItemIcon: "📈",
            insightItem: (
                <Span>
                    <BlurredSpan>XX</BlurredSpan>% more expensive than competitors
                </Span>
            ),
            isPositive: true
        },
        {
            analysisTitle: "✨Best Description",
            menuItemIcon: "",
            menuItemTitle: "Garlic and Parmesan bread",
            insightItemIcon: "🔮",
            insightItem: (
                <Span>
                    Optimized Description <BlurredSpan>XXXXx xxxxx XX xx xxx</BlurredSpan>
                </Span>
            ),
            isPositive: false
        },
        {
            analysisTitle: "💲Best Value for Price",
            menuItemIcon: "",
            menuItemTitle: "Applewood Smoked Pizza",
            insightItemIcon: "🔮",
            insightItem: (
                <Span>
                    Missing ingredient <BlurredSpan>XXXXxxXXxxx</BlurredSpan>
                </Span>
            ),
            isPositive: false
        }
    ] as Props[];

    return (
        <Box bg="gray.100" w="100%" maxW={"1200px"}>
            {/* <Box
				background={`linear-gradient(-45deg, ${menuColor} 0%, ${insightColor} 100%)`}
				position={"absolute"}
				top={0}
				left={0}
				width={"100%"}
				height={"100%"}
				opacity={0.5}
			/> */}
            {/* <OnlineHelmet restaurant={shop as any} />
			<Media queries={{ small: { maxWidth: 991 } }}>
				{matches => (matches.small ? <OnlineHeaderMobile {...props as any} /> : <OnlineHeaderDesktop {...props as any} />)}
			</Media> */}
            <Stack
                isInline
                position="relative"
                w="100%"
                h="auto"
                p={4}
                alignItems="baseline"
                stretch={2}
                background={`linear-gradient(90deg, ${menuColor} 0%, ${insightColor} 100%)`}
            >
                <Header
                    m={0}
                    as="h1"
                    size="xl"
                    fontSize="3rem"
                    color="newPrimary"
                    fontFamily="qopla"
                    textAlign="center"
                >
                    Qopla
                </Header>
                <Box m={0} as="h3" color="gray.100" textAlign="center">
                    Menu Analysis
                </Box>
            </Stack>

            <Stack h="auto">
                <Flex
                    h="auto"
                    direction="row"
                    justifyContent="space-between"
                    m={2}
                    paddingTop={0}
                    paddingBottom={2}
                    borderBottom={"inset"}
                >
                    <QMenuAnalysisTitle>Your Menu</QMenuAnalysisTitle>
                    <QMenuAnalysisTitle color="blue.500">Your Insights</QMenuAnalysisTitle>
                </Flex>
                {testRows.map((testRow, index) => (
                    <QMenuAnalysisResultRow key={index} {...testRow} />
                ))}
            </Stack>

            <ConsumerFooter />
            <Stack
                width="100%"
                position="sticky"
                bottom={0}
                zIndex={999}
                h="auto"
                backgroundColor={"gray.100"}
                stretch={0}
            >
                <Box
                    h="auto"
                    textAlign="center"
                    fontWeight="bold"
                    alignContent={"center"}
                    justifyContent={"center"}
                    fontSize="1.15rem"
                    m={0}
                    p={2}
                >
                    🪄🔮Unlock more insights now!
                </Box>
                <NewButton themeColor="blue" color="white" rounded={0} fontSize="1.25rem" h="3rem">
                    Join Us
                </NewButton>
            </Stack>
        </Box>
    );
};
