import { image1PreviewResponse, image2PreviewResponse, image3PreviewResponse, image4PreviewResponse, } from "./testdata"

export const testImages = [
	{
		image: "https://qopla.s3.eu-west-1.amazonaws.com/qopla/qmenu-testimage-1.jpg",
		response: image1PreviewResponse
	},
	{
		image: "https://qopla.s3.eu-west-1.amazonaws.com/qopla/qmenu-testimage-2.jpg",
		response: image2PreviewResponse
	},
	{
		image: "https://qopla.s3.eu-west-1.amazonaws.com/qopla/qmenu-testimage-3.jpg",
		response: image3PreviewResponse
	},
	{
		image: "https://qopla.s3.eu-west-1.amazonaws.com/qopla/qmenu-testimage-4.jpg",
		response: image4PreviewResponse
	},
]