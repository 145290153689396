/**
 * NOTE: This file is a WIP
 * This will likely become an extension of the Upload feature which will show the user an analysis of the menu they uploaded
 */
import React, { useState } from "react";
import { FaExternalLinkAlt } from "@react-icons/all-files/fa/FaExternalLinkAlt";

import { useEffectOnce } from "Hooks";
import { Box, Link, Stack, Text } from "Atoms";
import { Menu, MenuProductCategory, Shop } from "Types";
import { useTheme } from "Providers";
import { QAnalysisDataAPI } from "./QAnalysisAPI";
import { BOCard, BOCardBody } from "../../shared";

type Props = {
    menuId: string;
    menuCategoryId: string;
};

export const QMenuInfoComponent = ({ menuId, menuCategoryId }: Props) => {
    const [menuInfo, setMenuInfo] = useState<Menu>();
    const [shopInfo, setShopInfo] = useState<Shop>();
    const [menuCategoryInfo, setMenuCategoryInfo] = useState<MenuProductCategory>();
    const { colors } = useTheme();

    useEffectOnce(() => {
        const fetchMenuInfo = async () => {
            const menuInfo = await QAnalysisDataAPI.getMenuById(menuId);
            setMenuInfo(menuInfo);

            const menuCategoryInfo = await QAnalysisDataAPI.getMenuProductCategoryById(menuId, menuCategoryId);
            setMenuCategoryInfo(menuCategoryInfo);

            const shopInfo = await QAnalysisDataAPI.getShopByMenuId(menuId);
            setShopInfo(shopInfo);
        };

        fetchMenuInfo();
    });

    const restaurantOnlineOrderUrl = shopInfo?.settings?.onlineSettings?.restaurantOnlineOrderUrl;

    return (
        <BOCard>
            <BOCardBody>
                {restaurantOnlineOrderUrl ? (
                    <Link href={restaurantOnlineOrderUrl} isExternal>
                        <Stack isInline alignItems="baseline">
                            <Text as="h4">
                                {shopInfo?.name} - {menuInfo?.name}
                            </Text>
                            <Box as={FaExternalLinkAlt} size="1rem" color={colors.blue["800"]} />
                        </Stack>
                    </Link>
                ) : (
                    <Text as="h4">
                        {shopInfo?.name} - {menuInfo?.name}
                    </Text>
                )}
                {menuInfo?.description && <Text as="p">{menuInfo?.description}</Text>}
            </BOCardBody>
        </BOCard>
    );
};
