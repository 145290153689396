export const image3PreviewResponse =
	[
		{
			"title": "chess",
			"price": 5,
			"category": "APPETIZER"
		},
		{
			"title": "tomatou",
			"price": 5,
			"category": "APPETIZER"
		},
		{
			"title": "beef carpaccio",
			"price": 10,
			"category": "APPETIZER"
		},
		{
			"title": "apple sauce",
			"price": 8,
			"category": "APPETIZER"
		},
		{
			"title": "chicken bacon roll",
			"price": 15,
			"category": "ENTREES"
		},
		{
			"title": "beef steak",
			"price": 25,
			"category": "ENTREES"
		},
		{
			"title": "extreme pizza",
			"price": 20,
			"category": "ENTREES"
		},
		{
			"title": "shrimp pasta",
			"price": 15,
			"category": "ENTREES"
		},
		{
			"title": "apple pie",
			"price": 8,
			"category": "DESSERT"
		},
		{
			"title": "ice-cream",
			"price": 8,
			"category": "DESSERT"
		},
		{
			"title": "shortcake",
			"price": 6,
			"category": "DESSERT"
		},
		{
			"title": "sweet potato",
			"price": 5,
			"category": "DESSERT"
		},
		{
			"title": "black coffee",
			"price": 10,
			"category": "DRINKS"
		},
		{
			"title": "milk",
			"price": 8,
			"category": "DRINKS"
		},
		{
			"title": "fresh orange juice",
			"price": 10,
			"category": "DRINKS"
		},
		{
			"title": "beer",
			"price": 10,
			"category": "DRINKS"
		}
	]