import React, { useState } from "react";
import Select, { ActionMeta, ValueType } from "react-select";

import { SelectOption } from "Molecules";
import { LOG_STATUS } from "Types";
import { Languagekey, useLanguage } from "Providers";
import { Box } from "Atoms";

type Props = {
    onFilterMenuLogs: (selectedOptions: LOG_STATUS[]) => void;
};

export const MenuLogFilter: React.FC<Props> = ({ onFilterMenuLogs }) => {
    const [filterValue, setFilterValue] = useState<LOG_STATUS[]>([LOG_STATUS.SUCCESS, LOG_STATUS.FAILED]);
    const { translate } = useLanguage();

    const menuLogStatusOptions: SelectOption[] = Object.entries(LOG_STATUS).map((status: [string, LOG_STATUS]) => {
        const [label, value] = status;
        return {
            label: translate(label as Languagekey),
            value: value
        };
    });

    const selectedOptions: SelectOption[] = filterValue.map((value: LOG_STATUS) => {
        return {
            label: translate(value),
            value: value
        };
    });

    const onChangeLogStatus = (value: ValueType<SelectOption, boolean>, _: ActionMeta<SelectOption>) => {
        const selectedOptions = value as SelectOption[];
        const logStatus =
            selectedOptions?.map((select: SelectOption) => {
                return select.value as LOG_STATUS;
            }) ?? [];
        setFilterValue(logStatus);
        onFilterMenuLogs(logStatus);
    };

    return (
        <Box width="60%" fontSize="0.8rem" color="gray.700" mt={3}>
            {/** @ts-ignore */}
            <Select
                options={menuLogStatusOptions}
                noOptionsMessage={() => translate("noAlternative")}
                placeholder={translate("status")}
                onChange={onChangeLogStatus}
                defaultValue={selectedOptions}
                isMulti
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
            />
        </Box>
    );
};
