export const image4PreviewResponse = [
	{
		"title": "Margherita",
		"description": "tomats\u00e5s, ost",
		"price": 110,
		"category": "Pizzor"
	},
	{
		"title": "Vesuvio",
		"description": "tomats\u00e5s, ost, skinka",
		"price": 110,
		"category": "Pizzor"
	},
	{
		"title": "Capricciosa",
		"description": "tomats\u00e5s, ost, skinka, champinjoner",
		"price": 110,
		"category": "Pizzor"
	},
	{
		"title": "Hawaii",
		"description": "tomats\u00e5s, ost, skinka, ananas",
		"price": 110,
		"category": "Pizzor"
	},
	{
		"title": "Kebabpizza",
		"description": "tomats\u00e5s, ost, kebab, l\u00f6k, feferoni, kebabs\u00e5s",
		"price": 125,
		"category": "Pizzor"
	},
	{
		"title": "Mozzarellapizza",
		"description": "tomats\u00e5s, f\u00e4rsk mozzarella, basilika",
		"price": 130,
		"category": "Pizzor"
	},
	{
		"title": "Grekisk sallad",
		"description": "isbergssallad, tomat, gurka, fetaost, oliver, l\u00f6k, pepparioni",
		"price": 110,
		"category": "Sallader"
	},
	{
		"title": "Tonfisksallad",
		"description": "isbergssallad, tomat, gurka, tonfisk, l\u00f6k, \u00e4gg",
		"price": 110,
		"category": "Sallader"
	},
	{
		"title": "R\u00e4ksallad",
		"description": "isbergssallad, tomat, gurka, r\u00e4kor, \u00e4gg, citron",
		"price": 110,
		"category": "Sallader"
	},
	{
		"title": "Lasagne",
		"description": null,
		"price": 115,
		"category": "\u00d6vriga r\u00e4tter"
	},
	{
		"title": "Piroger",
		"description": "med k\u00f6ttfyllning",
		"price": 100,
		"category": "\u00d6vriga r\u00e4tter"
	},
	{
		"title": "Pizzasallad",
		"description": null,
		"price": 10,
		"category": "Tillbeh\u00f6r"
	},
	{
		"title": "Vitl\u00f6kss\u00e5s",
		"description": null,
		"price": 15,
		"category": "Tillbeh\u00f6r"
	},
	{
		"title": "Bearnaises\u00e5s",
		"description": null,
		"price": 15,
		"category": "Tillbeh\u00f6r"
	}
];