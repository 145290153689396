import React from "react";

import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { NewButton, ReactJson } from "Atoms";
import { useLanguage } from "Providers";

type Props = {
    request: string;
    onClose: () => void;
    response?: string;
};

export const MenuSyncPopUp: React.FC<Props> = ({ request, onClose, response }) => {
    const { translate } = useLanguage();

    return (
        <Modal
            placement="top"
            isScrolling
            size="xxl"
            height="80vh"
            open
            onClose={onClose}
            borderWidth="1px"
            borderColor="gray.900"
            borderStyle="solid"
        >
            <ModalHeader>{translate("menuData")}</ModalHeader>
            <ModalCloseBtn onClick={onClose} />
            <ModalBody>
                {response && <ReactJson displayDataTypes={false} src={JSON.parse(response)} collapsed={1} />}
                <ReactJson displayDataTypes={false} src={JSON.parse(request)} collapsed={1} />
            </ModalBody>
            <ModalActions>
                <NewButton themeColor="green" onClick={onClose}>
                    {translate("close")}
                </NewButton>
            </ModalActions>
        </Modal>
    );
};
