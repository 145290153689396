import React, { useState } from "react";
import Slider, { Settings } from "react-slick";

import { BaseBoxProps, Box } from "Atoms";

type Props = {
    activeColor?: string;
    inactiveColor?: string;
} & BaseBoxProps;

const SELLING_POINTS = [
    {
        text: "Qopla customers experience a 34% increase in online orders compared to competitors."
    },
    {
        text: "Did you know? 80% of Qopla users report improved customer satisfaction within the first six months."
    },
    {
        text: "Qopla's self-service options help restaurants reduce wait times by up to 25%."
    },
    {
        text: "Qopla customers see an average of 20% growth in repeat diners annually."
    },
    {
        text: "Using Qopla, restaurants can streamline their operations, saving up to 15 hours per week on order management."
    },
    {
        text: "Did you know? Qopla integrates with over 50 restaurant POS systems, ensuring seamless connectivity."
    },
    {
        text: "Qopla's real-time tracking feature reduces customer service inquiries by 30%."
    },
    {
        text: "Restaurants using Qopla see a 40% improvement in order accuracy, enhancing overall customer experience."
    },
    {
        text: "Did you know? Qopla provides personalized marketing insights, increasing online reservations by 18%."
    },
    {
        text: "Qopla's customer support is rated 4.8 out of 5, reflecting its commitment to user satisfaction."
    }
];

export const SellingPointsCarousel = ({ activeColor, inactiveColor, ...rest }: Props) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    var settings: Settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5250,
        arrows: false,
        nextArrow: undefined,
        prevArrow: undefined,
        afterChange: (currentSlide: number) => {
            setCurrentSlide(currentSlide);
        },
        customPaging: i => {
            return (
                <Box
                    w="1rem"
                    h="1rem"
                    backgroundColor={i === currentSlide ? activeColor : inactiveColor}
                    borderRadius="50%"
                    mt={5}
                    mx={4}
                    style={{ transition: "all 0.3s" }}
                />
            );
        }
    };
    return (
        <Box {...rest}>
            {/** @ts-ignore */}
            <Slider {...settings}>
                {SELLING_POINTS.map((point, index) => {
                    return (
                        <Box w="auto" key={index} className="selling-point" textAlign="center">
                            {point.text}
                        </Box>
                    );
                })}
            </Slider>
        </Box>
    );
};
