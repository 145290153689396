import Axios from "axios";

import { envConstants } from "Constants";
import { MenuUploadInitialScanResults, MenuUploadParseResults, MenuUserInfo } from "Types";

const removeTrailingSlash = (url: string | undefined) => {
	if (!url) {
		return "";
	}
	return url.replace(/\/$/, "");
}


const HOSTNAME = removeTrailingSlash(envConstants.QANALYSIS_SERVICE_URL);

const ServerEndpoints = {
	SEARCH_HYBRID: `${HOSTNAME}/menuitems/search/hybrid`,
	SEARCH: `${HOSTNAME}/menuitems/search`,
	MENU_INITIAL_SCAN: `${HOSTNAME}/menu/initial-scan`,
	MENU_PARSE: `${HOSTNAME}/menu/parse`,
	SIGNUP: `${HOSTNAME}/menu/signup`,

	// Direct DB Operations
	GET_SHOP_BY_ID: `${HOSTNAME}/shop/{shop_id}`,
	GET_MENU_BY_ID: `${HOSTNAME}/menu/{menu_id}`,
	GET_MENU_PRODUCT_CATEGORY_BY_ID: `${HOSTNAME}/menu/{menu_id}/productCategory/{menu_product_category_id}`,
	GET_SHOP_BY_MENU_ID: `${HOSTNAME}/shop/menu/{menu_id}`,
};

// TODO: Figure out if QAnalysisDataAPI and QAnalysisMenuAPI should be separate
export const QAnalysisDataAPI = {
	getShopById: async (shopId: string) => {
		const response = await Axios.get(ServerEndpoints.GET_SHOP_BY_ID.replace("{shop_id}", shopId));
		return JSON.parse(response.data);
	},
	getMenuById: async (menuId: string) => {
		const response = await Axios.get(ServerEndpoints.GET_MENU_BY_ID.replace("{menu_id}", menuId));
		return JSON.parse(response.data);
	},
	getMenuProductCategoryById: async (menuId: string, menuProductCategoryId: string) => {
		const response = await Axios.get(ServerEndpoints.GET_MENU_PRODUCT_CATEGORY_BY_ID.replace("{menu_id}", menuId).replace("{menu_product_category_id}", menuProductCategoryId));
		return JSON.parse(response.data);
	},
	getShopByMenuId: async (menuId: string) => {
		const response = await Axios.get(ServerEndpoints.GET_SHOP_BY_MENU_ID.replace("{menu_id}", menuId));
		return JSON.parse(response.data);
	},
	/**
	 * Searches the menu item name
	 * @param searchTerm
	 * @returns a list of menu items that match the search term
	 */
	searchMenuItems: async (searchTerm: string) => {
		const response = await Axios.post(ServerEndpoints.SEARCH, {
			search_term: searchTerm
		});
		return JSON.parse(response.data);
	},
	/**
	 * Searches both the menu item name and the menu item description
	 * @param searchTerm
	 * @param limit
	 * @returns a list of menu items that match the search term
	 */
	searchHybrid: async (searchTerm: string, limit: number) => {
		const response = await Axios.post(ServerEndpoints.SEARCH_HYBRID, {
			search_term: searchTerm,
			limit: limit
		});
		return JSON.parse(response.data);
	}
}

export const QAnalysisMenuAPI = {
	uploadMenuInitialScan: async (base64image: string): Promise<MenuUploadInitialScanResults> => {
		const response = await Axios.post(ServerEndpoints.MENU_INITIAL_SCAN, {
			base64image: base64image
		});

		return response.data as MenuUploadInitialScanResults;
	},
	uploadMenuParse: async (base64image: string): Promise<MenuUploadParseResults> => {
		const response = await Axios.post(ServerEndpoints.MENU_PARSE, {
			base64image: base64image
		});

		return response.data as MenuUploadParseResults;
	},
	postMenuSignup: async (parsedMenuId: string, userInfo: MenuUserInfo): Promise<{ success: boolean }> => {
		const requestObject = {
			parsed_menu_id: parsedMenuId,
			name: userInfo.name,
			email: userInfo.email,
			phoneNumber: userInfo.phoneNumber,
			restaurant_name: userInfo.restaurantName
		};

		const response = await Axios.post(ServerEndpoints.SIGNUP, {
			...requestObject
		});

		return response.data;
	}
}