/**
 * NOTE: This file is a WIP
 * Most likely it will morph into an Admin component that will allow the user to search for similar products in the DB
 */
import React from "react";
import { useForm } from "react-hook-form";

import { Button, Flex, Header, RHFormInput, Stack, Text } from "Atoms";
import { BOCard, BOCardBody, BackOfficeContainer } from "../../shared";
import { initialSearchResults } from "./TestSearchResults";
import { QMenuInfoComponent } from "./QMenuInfo";
import { QAnalysisDataAPI } from "./QAnalysisAPI";

type FormValues = {
    queryString: string;
};

type MenuItem = {
    data: string; // JSON string
    distance: number;
    menu_item_text: string;
    description: string;
    menu_category_id: string;
    menu_id: string;
    menu_item_id: string;
    menu_product_id: string;
    name: string;
    ref_product_id: string;
};

export type SearchResults = {
    menu_items: MenuItem[];
};

// The color should be graded from green to red based on the distance
// Close to 0 is the greenest
// Close to 1 is the reddest
// Gradient between
const calculateColorBasedOnDistance = (distance: number) => {
    const r = Math.round(distance * 255);
    const g = Math.round((1 - distance) * 255);

    return `rgb(${r},${g},0)`;
};

export const QMenuSearchTest = () => {
    const [searchResults, setSearchResults] = React.useState<SearchResults>(initialSearchResults);

    const onSubmit = async (formValues: FormValues) => {
        const searchTerm = formValues?.queryString;

        // Create an axios post like  curl -X POST http://localhost:5000/menuitems/search -H "Content-Type: application/json" -d '{"search_term": "soya"}'
        // set the content type to application/json

        const searchResults = await QAnalysisDataAPI.searchMenuItems(searchTerm);
        setSearchResults(searchResults);
    };

    const {
        control,
        formState: { isSubmitting, isValid, isDirty },
        handleSubmit
    } = useForm<any>({
        defaultValues: {},
        mode: "onBlur"
    });

    console.log("searchResults", searchResults);

    // Remove duplicate search results
    const menuItemsResultsWithoutDuplicates = searchResults?.menu_items.filter(
        (menuItem, index, self) => index === self.findIndex(t => t.menu_item_text === menuItem.menu_item_text)
    );

    return (
        <BackOfficeContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <RHFormInput
                    control={control}
                    name="queryString"
                    formLabel={"Query"}
                    placeholder={"Menu Item Text"}
                    isFullWidth
                    isMandatory
                />

                <Button
                    type="submit"
                    themeColor="green"
                    isLoading={isSubmitting}
                    disabled={!isValid || !isDirty}
                    fullWidth
                >
                    Send
                </Button>
            </form>
            <Header>Search Results</Header>
            <Stack mt={6} stretch={3}>
                {menuItemsResultsWithoutDuplicates?.map((menuItem, index) => {
                    const matchColor = calculateColorBasedOnDistance(menuItem.distance);

                    return (
                        <BOCard>
                            <BOCardBody>
                                <Stack key={index} stretch={2}>
                                    <Flex justify="space-between">
                                        <Stack isInline>
                                            <Stack>
                                                <Text as="h2" fontWeight="bold" color={matchColor}>
                                                    {Math.trunc(menuItem.distance * 100) / 100}
                                                </Text>

                                                {/* If the distance is less than 0.2, show a good match */}
                                                {menuItem.distance <= 0.2 && (
                                                    <Text fontWeight="bold" color={matchColor} fontSize={14}>
                                                        GOOD MATCH
                                                    </Text>
                                                )}
                                            </Stack>
                                            <Stack>
                                                <Text as="h3" fontWeight="bold">
                                                    {menuItem.menu_item_text}
                                                </Text>
                                                <Text>{menuItem.description}</Text>
                                            </Stack>
                                        </Stack>
                                        {/* <Stack>
                                                <Text>
                                                    <ToolTip
                                                        placement='top'
                                                        text="This will show the name of the menu category">
                                                        MenuCat:{menuItem.menu_category_id}
                                                    </ToolTip>
                                                </Text>

                                                <Link href={`www.qopla.com/admin/menu/${menuItem.menu_id}`}>
                                                    <Text>MenuId:{menuItem.menu_id}</Text>
                                                </Link>
                                            </Stack> */}
                                    </Flex>
                                </Stack>
                                <QMenuInfoComponent
                                    menuId={menuItem.menu_id}
                                    menuCategoryId={menuItem.menu_category_id}
                                />
                            </BOCardBody>
                        </BOCard>
                    );
                })}
            </Stack>
        </BackOfficeContainer>
    );
};
