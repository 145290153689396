import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import confetti from "canvas-confetti";
import { IoMdSend } from "@react-icons/all-files/io/IoMdSend";

import { showToastError } from "Utils";
import { IModalContext, TranslateFunc, modals, useLanguage } from "Providers";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader, ModalActions } from "Molecules";
import { Flex, Box, Stack, Text, Button, RHPhoneInputWithCountryCode, RHFormInput } from "Atoms";
import { MENU_SIGNUP_STATE, useMenuUploadStore } from "Stores";
import { QAnalysisMenuAPI } from "./QAnalysisAPI";
import { MenuUserInfo } from "Types";

type Props = {
    initialUserInfo: MenuUserInfo | undefined;
    onModalClosed: () => void;
};

const validationSchema = (translate: TranslateFunc) =>
    Yup.object().shape({
        name: Yup.string().required(translate("requiredError")),
        email: Yup.string().trim().required(translate("requiredError")).email(translate("formErrorValidEmail")),
        phoneNumber: Yup.string().required(translate("requiredError")),
        restaurantName: Yup.string().required(translate("requiredError"))
    });

export const QMenuPreviewSignupModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    // Upload result is used to get the parsedMenuId
    const { setUserInfo, uploadResults, menuSignupState, setMenuSignupState } = useMenuUploadStore();
    const { translate } = useLanguage();
    const { initialUserInfo } = modalContent;

    const defaultValues = initialUserInfo
        ? initialUserInfo
        : {
            name: "",
            email: "",
            phoneNumber: "",
            restaurantName: ""
        };

    const {
        control,
        handleSubmit,
        watch,
        formState: { isSubmitting }
    } = useForm<MenuUserInfo>({
        defaultValues,
        resolver: yupResolver(validationSchema(translate)),
        mode: "onSubmit"
    });

    // Watch for changes in the form and update the store accordingly
    const updatedUserInfo = watch();
    useEffect(() => {
        setUserInfo(updatedUserInfo);
    }, [updatedUserInfo]);

    const onCloseModal = () => {
        closeModal(modals.qMenuPreviewSignupModal);
    };

    const onSubmit = handleSubmit(async (values: MenuUserInfo) => {
        const result = await signup(values);

        if (result) {
            setMenuSignupState(MENU_SIGNUP_STATE.SIGNED_UP);
            confetti();
        }
    });

    // Signup functionality
    const parsedMenuId = uploadResults?.parsed_menu_id;
    const signup = async (userInfo: MenuUserInfo) => {
        if (!parsedMenuId) {
            showToastError(translate("somethingWentWrong"));
            console.error("No parsed menu id found. Cannot sign up.");
            return;
        }

        if (!userInfo) {
            showToastError(translate("somethingWentWrong"));
            console.error("No user info found. Cannot sign up.");
            return;
        }

        const data = await QAnalysisMenuAPI.postMenuSignup(parsedMenuId, userInfo);
        return data;
    };

    if (menuSignupState === MENU_SIGNUP_STATE.SIGNED_UP) {
        return (
            <Modal size="xs" open onClose={onCloseModal} isScrolling overlayProps={{ zIndex: 1400 }}>
                <ModalHeader fontSize="2xl">
                    {translate("welcome")}
                    <ModalCloseBtn />
                </ModalHeader>
                <ModalBody pt={2}>
                    <Text>{translate("menuSignupThankYouLine1")}</Text>
                    <Text>{translate("menuSignupThankYouLine2")}</Text>
                </ModalBody>
                <ModalActions display="flex" justifyContent="flex-end">
                    <Button themeColor="blue" onClick={onCloseModal}>
                        <Text>{translate("okGreat")}</Text>
                    </Button>
                </ModalActions>
            </Modal>
        );
    }

    return (
        <Modal size="xs" open onClose={onCloseModal} isScrolling overlayProps={{ zIndex: 1400 }}>
            <ModalHeader fontSize="2xl">
                {translate("welcome")}
                <ModalCloseBtn />
            </ModalHeader>
            <ModalBody pt={2}>
                <form>
                    <Flex direction="column">
                        <Text fontSize="md" fontWeight="bold">
                            {translate("menuSignupHeader")}
                        </Text>
                        <Box mt={4}>
                            <Stack stretch={4} flex="1">
                                <RHFormInput
                                    control={control}
                                    name="name"
                                    placeholder={translate("name")}
                                    isFullWidth
                                />
                                <RHFormInput control={control} name="email" placeholder={translate("email")} />
                                <RHPhoneInputWithCountryCode control={control} name="phoneNumber" />
                                <RHFormInput
                                    control={control}
                                    name="restaurantName"
                                    placeholder={translate("restaurantName")}
                                    isFullWidth
                                />
                            </Stack>
                        </Box>
                    </Flex>
                </form>
            </ModalBody>
            <ModalActions display="flex" justifyContent="flex-end">
                <Button
                    themeColor="blue"
                    onClick={onSubmit}
                    leftIcon={IoMdSend}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                >
                    {translate("send")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
