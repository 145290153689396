import React from "react";
import { GiPlainCircle } from "@react-icons/all-files/gi/GiPlainCircle";

import { OrderType } from "Types";
import { isThirdPartyLog, getLogStatusColour } from "./functions";
import { ServiceIconForOrderHistory } from "TempUtils";
import { Box, Flex, NewGrid as Grid, NewButton, Text } from "Atoms";
import { MenuLog } from "./types";
import { useLanguage } from "Providers";

type Props = {
    menuLog: MenuLog.All;
    handleLoadLogById: (logId: string) => void;
};

export const MenuLogTile: React.FC<Props> = ({ menuLog, handleLoadLogById }) => {
    const { translate } = useLanguage();
    const displayDate = menuLog.displayDate.format("YYYY-MM-DD HH:mm:ss");

    let logStatusColour = getLogStatusColour(menuLog.status);
    if (isThirdPartyLog(menuLog)) {
        const displayLog = menuLog as MenuLog.ThirdParty;
        return (
            <Grid
                templateColumns={["repeat(2, 2rem) 2fr 1fr", "repeat(2, 4rem) 2fr 1fr"]}
                style={{ minHeight: "3rem", justifyContent: "center", margin: "1rem 0rem", width: "100%" }}
                alignItems="center"
            >
                <Box as={GiPlainCircle} color={logStatusColour} marginRight="auto" />
                <Box maxW="min-content" mt={3}>
                    {ServiceIconForOrderHistory(displayLog.thirdPartyDeliveryType)}
                </Box>
                <Flex direction="column" ml={6}>
                    <Text mb={2} fontWeight="bold" fontSize="0.9rem" color="gray.700">
                        {translate("date")}:
                        <Box as="span" ml={2} color="gray.600">
                            {displayDate}
                        </Box>
                    </Text>
                    <Text mb={1} fontWeight="bold" fontSize="0.9rem" color="gray.800">
                        {translate("restaurant")}:
                        <Box as="span" ml={2} color="gray.600">
                            {displayLog.shop?.name}
                        </Box>
                    </Text>
                </Flex>

                <NewButton
                    themeColor="blue"
                    width="min-content"
                    marginLeft="auto"
                    onClick={() => {
                        handleLoadLogById(displayLog.id);
                    }}
                >
                    {translate("details")}
                </NewButton>
            </Grid>
        );
    }

    const displayLog = menuLog as MenuLog.Qopla;

    return (
        <Grid
            templateColumns={[
                "repeat(2, 2rem) repeat(2,minmax(min-content,1fr))",
                "repeat(2, 4rem) repeat(2,minmax(min-content,1fr))"
            ]}
            style={{ minHeight: "3rem", justifyContent: "center", margin: "1rem 0rem" }}
            alignItems="center"
        >
            <Box as={GiPlainCircle} color={logStatusColour} marginRight="auto" />
            <Box maxW="min-content">{ServiceIconForOrderHistory(OrderType.WEB)}</Box>
            <Flex direction="column" gridColumn="3 / span 2" ml={6}>
                <Text mb={1} fontWeight="bold" fontSize="0.9rem" color="gray.800" width="100%">
                    {translate("date")}:
                    <Box as="span" ml={2} color="gray.600">
                        {displayDate}
                    </Box>
                </Text>
                <Text mb={1} fontWeight="bold" fontSize="0.9rem" color="gray.800">
                    {translate("restaurants")}:
                    <Box as="span" ml={2} color="gray.700">
                        {displayLog.numberOfOccurrences}
                    </Box>
                </Text>
                <Text mb={1} fontWeight="bold" fontSize="0.9rem" color="gray.800">
                    {translate("email")}:
                    <Box as="span" ml={2} color="gray.700">
                        {displayLog.username}
                    </Box>
                </Text>
            </Flex>
        </Grid>
    );
};
