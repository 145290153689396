import React from "react";
import Truncate from "react-truncate";
import { FaImage } from "@react-icons/all-files/fa/FaImage";

import { CompanyLocale, CountryLocaleId, CurrencySymbol, MenuPreviewProduct } from "Types";
import { Text, Flex, Box, BaseBoxProps, Button, Header, Image } from "Atoms";
import { formatFinancialNumbers } from "Utils";

type MenuPreviewProductProps = {
    product: MenuPreviewProduct;
} & BaseBoxProps;

const SHORT_DESCRIPTION = 50;
const ImagePlaceholder = (props: BaseBoxProps) => (
    <Box
        h="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="gray.500"
        fontSize="2rem"
        padding={2}
        {...props}
    >
        <FaImage style={{ width: "auto", height: "100%" }} />
    </Box>
);

export const QMenuPreviewProduct = ({ product, ...rest }: MenuPreviewProductProps) => {
    const imageUrl = "";
    // Comment this in if you want to see what the product card looks like with an image
    // const imageUrl = "https://s3-eu-west-1.amazonaws.com/qopla-dev/cc/product/rice-600pxl.png";

    const isDisabled = false;

    const reservedLinesNumberForName = product.description?.length <= SHORT_DESCRIPTION ? 2 : 1;
    const price = product.price as number;

    // TODO: In the future, we want to likely attempt to parse the currency based on the language and best guess currency
    const companyLocale = new CompanyLocale(CountryLocaleId.sv_SE);
    companyLocale.setCurrency(CurrencySymbol.sv_SE);
    const priceText = formatFinancialNumbers(price, companyLocale);

    const pseudoStyles = {
        transform: isDisabled ? "" : ["", "", "", "scale(1.01)"],
        boxShadow: isDisabled
            ? ""
            : ["", "", "", "0 8px 12px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"]
    };

    return (
        <Flex height="auto" width="100%" minWidth="100%" {...rest}>
            <Button
                isDisabled={isDisabled}
                position="relative"
                border="none"
                padding="0"
                height="100px"
                maxHeight="100px"
                fontWeight={400}
                borderRadius="0.75rem"
                width="100%"
                whiteSpace="pre-wrap"
                backgroundColor="base"
                cursor={isDisabled ? "not-allowed" : "pointer"}
                overflow="hidden"
                boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                transition="all 0.2s cubic-bezier(0.44, 2.1, 0.04, 1.65)"
                _hover={pseudoStyles}
                _active={pseudoStyles}
            >
                <Flex width="100%" textAlign="left">
                    <Flex width="100%">
                        {imageUrl ? (
                            <Flex flex="0 0 100px" padding={1}>
                                <Image
                                    alt={product.title}
                                    src={imageUrl}
                                    fallbackSrc={""}
                                    maxHeight="100px"
                                    maxWidth="100px"
                                    objectFit="cover"
                                    borderRadius="0.75rem"
                                    opacity={isDisabled ? 0.5 : 1}
                                />
                            </Flex>
                        ) : (
                            <Flex flex="0 0 100px" padding={1} justifyContent="center">
                                <ImagePlaceholder />
                            </Flex>
                        )}
                        <Flex flex="1">
                            <Flex direction="column" p={3} flex="1">
                                <Box>
                                    <Header
                                        size="md"
                                        as="h4"
                                        margin="0"
                                        wordBreak="break-word"
                                        textTransform="capitalize"
                                    >
                                        {/** @ts-ignore */}
                                        <Truncate lines={reservedLinesNumberForName} ellipsis={<span>...</span>}>
                                            {product.title}
                                        </Truncate>
                                    </Header>
                                </Box>

                                <Text wordBreak="break-word">
                                    {/** @ts-ignore */}
                                    <Truncate lines={3}>{product.description || ""}</Truncate>
                                </Text>
                            </Flex>
                            <Flex direction="column" flex="none" justify={"flex-end"}>
                                <Text fontWeight="bold" fontSize="1.2rem" padding="0 0.5rem 0.5rem 0">
                                    {priceText}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Button>
        </Flex>
    );
};
