import moment from "moment";
import momentTimezone from "moment-timezone";
import { RegionTimeZone, LOG_STATUS } from "Types";
import { MenuLog } from "./types";

/**
 * [FUNCTION] check which log it is by sync operation
 * @param {QoplaLog | ThirdPartyLog} value
 * @returns {boolean} true or false
 * @example isThirdPartyLog(ThirdPartyLog) => true
 */
export const isThirdPartyLog = (value: MenuLog.All) => {
    return Reflect.has(value, "syncOperation");
};

/**
 * [FUNCTION] sorts between date in the menu logs
 * @description either between createdAt (Menu sync) or updatedAt (qopla change log)
 * @description will check the type of the log and use the correct date
 * @param {MenuLog.All} logA could be Qopla | ThirdParty
 * @param {MenuLog.All} logB could be Qopla | ThirdParty
 * @returns difference between dates
 * @example allMenuLogs.sort(sortByDate);
 */
export const sortByDateDesc = (logA: MenuLog.All, logB: MenuLog.All) => {
    return moment(logB.displayDate).diff(logA.displayDate);
};

/**
 * [FUNCTION] return new moment date by timezone
 * @param {Date} currentDate date from the menu sync logs
 * @param {RegionTimeZone} timezone either taken from company locale or send in RegionTimeZone.SE
 * @param type "ChangeLog" | "ThirdParty"
 * @returns new moment date
 * @example formatMenuSyncDate(new Date(), "Europe/Stockholm" (RegionTimeZone.SE), "ThirdParty")
 */
export const formatMenuSyncDate = (currentDate: Date, timezone: RegionTimeZone, type: MenuLog.LogType) => {
    if (type === "ThirdParty") {
        return momentTimezone(currentDate).tz(timezone);
    }
    const tZone = momentTimezone.tz(timezone).format("Z");
    return moment(currentDate).add(parseInt(tZone), "h");
};

/**
 * [FUNCTION] check if log is successful
 * @param status
 * @returns true or false
 */
export const isSuccessfulLog = (status: LOG_STATUS) => status === LOG_STATUS.SUCCESS;

/**
 * [FUNCTION] get log status colour
 * @param status
 * @returns string
 */
export const getLogStatusColour = (status: LOG_STATUS) => {
    switch (status) {
        case LOG_STATUS.SUCCESS: {
            return "green.500";
        }
        case LOG_STATUS.FAILED: {
            return "red.500";
        }
        case LOG_STATUS.IN_PROGRESS: {
            return "yellow.500";
        }
    }
};
