import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMedia } from "react-media";
import { HiOutlineSparkles } from "@react-icons/all-files/hi/HiOutlineSparkles";

import { Box, Stack, Flex, NewButton, Header, Button } from "Atoms";
import { ConsumerFooter } from "Organisms";
import { useMenuUploadStore } from "Stores";
import { modals, useLanguage, useModal } from "Providers";
import { LanguagePicker } from "Components";
import { LinkButton } from "Molecules";
import { testImages } from "./TestImages";
import { useTestDataArrowKeys } from "./useTestDataArrowKeys";
import { QMenuPreviewProduct } from "./QMenuPreviewProduct";
import { MenuPreviewProduct } from "Types";
import { PRODUCT_CATEGORY_UNCATEGORIZED } from "Utils";


// Get optional state MenuPreviewProduct[] from the router navigation
export const QMenuPreviewPage = () => {
    const [currentTestDataIndex, setCurrentTestDataIndex] = useState<undefined | number>();

    // Get the menu preview products from the store - A result of the API menu upload
    const { userInfo, uploadResults, menuPreviewProducts, setMenuPreviewProducts } = useMenuUploadStore();
    const { openModal } = useModal();
    const { translate } = useLanguage();
    const isSmallScreen = useMedia({ query: "(max-width: 991px)" });

    const menuPreviewProductsFromStore = !!menuPreviewProducts?.length
        ? menuPreviewProducts
        : ((uploadResults?.menu_items || []) as MenuPreviewProduct[]);

    useEffect(() => {
        if (Array.isArray(menuPreviewProductsFromStore) && menuPreviewProductsFromStore.length) {
            setMenuPreviewProducts(menuPreviewProductsFromStore);
        }
    }, [menuPreviewProductsFromStore]);

    // Listen for arrow left and arrow right
    useTestDataArrowKeys({
        currentTestDataIndex,
        setTestDataIndex: setCurrentTestDataIndex,
        numData: testImages.length
    });

    const testImage = currentTestDataIndex !== undefined ? testImages[currentTestDataIndex] : undefined;
    const testPreviewProducts = testImage?.response as MenuPreviewProduct[];

    // Decide to use the test preview products or the products from the store/API results
    const previewProducts = testPreviewProducts || menuPreviewProductsFromStore;

    const menuPreviewProductsByCategory = previewProducts?.reduce((acc, product) => {
        const productCategory = product.category ?? PRODUCT_CATEGORY_UNCATEGORIZED;

        if (!acc[productCategory]) {
            acc[productCategory] = [];
        }

        acc[productCategory].push(product);

        return acc;
    }, {} as Record<string, MenuPreviewProduct[]>);

    return (
        <Box height="100%" bg="gray.100">
            <Helmet>
                {/*
                 We want the title,
                 but we don't want the bots to index the preview page itself,
                 it has no content unless you were redirected here with local state
                */}
                <title>Qopla | {translate("menuPreviewPage")}</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                px={[2, 8]}
                py={4}
                color="black"
                h="auto"
                borderBottomWidth="1px"
                borderBottomStyle="solid"
                borderBottomColor="gray.200"
                backgroundColor={"white"}
            >
                <Flex align="center" mr={5} alignItems="baseline">
                    <Header
                        m={0}
                        as="h1"
                        size="xl"
                        fontSize="3rem"
                        color="newPrimary"
                        fontFamily="qopla"
                        textAlign="center"
                    >
                        Qopla
                    </Header>
                    <Flex
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        fontWeight={"bold"}
                        ml={2}
                        fontSize={["1rem", "1.25rem", "1.5rem"]}
                    >
                        <Box m={0} ml={2} color="gray.800" textAlign="center">
                            {translate("menuPreview")}
                        </Box>
                        <Box as={HiOutlineSparkles} color={"gray.800"} ml={1} />
                    </Flex>
                </Flex>
            </Flex>

            <Stack h="auto">
                {Object.entries(menuPreviewProductsByCategory).map(([category, products]) => (
                    <Stack key={category} p={2}>
                        <Header
                            as="h2"
                            textTransform="capitalize"
                            size="lg"
                            fontWeight="normal"
                            fontSize={"24px"}
                            m={0}
                        >
                            {category}
                        </Header>
                        <Box className="online-order-card-grid" p={2}>
                            {products.map((product, index) => (
                                <QMenuPreviewProduct key={index} product={product} />
                            ))}
                        </Box>
                    </Stack>
                ))}
            </Stack>

            <Stack
                width="100%"
                position="sticky"
                bottom={0}
                zIndex={999}
                h="auto"
                backgroundColor={"gray.100"}
                stretch={0}
            >
                <Box
                    h="auto"
                    textAlign="center"
                    fontWeight="bold"
                    alignContent={"center"}
                    justifyContent={"center"}
                    fontSize="1.15rem"
                    m={0}
                    p={2}
                    borderTopStyle="solid"
                    borderTopWidth="1px"
                    borderTopColor={"gray.300"}
                >
                    {translate("unlockMoreInsightsNow")}
                </Box>
                <NewButton
                    themeColor="blue"
                    color="white"
                    rounded={0}
                    fontSize="1.25rem"
                    h="3rem"
                    onClick={() => {
                        openModal(modals.qMenuPreviewSignupModal, {
                            initialUserInfo: userInfo,
                            onModalClosed: () => { }
                        });
                    }}
                >
                    {translate("joinUs")}
                </NewButton>
            </Stack>
        </Box>
    );
};
