export const image2PreviewResponse = [
	{
		"title": "YP STROMBOLI",
		"description": "For over four decades, the original recipe of deli meats, rich cheese and unique spices wrapped in fresh dough, baked golden brown, served w/ our homemade marinara on the side",
		"price": "14",
		"category": "PUB GRUB"
	},
	{
		"title": "YP BURGER",
		"description": "A juicy 1/2 lb patty of 100% Black Angus beef on a soft brioche bun topped w/ lettuce, tomato, onion rings, choice of cheese and a side of mayo + $0.50 ea Toppings (see pizza toppings)",
		"price": "12",
		"category": "PUB GRUB"
	},
	{
		"title": "FISH N CHIPS",
		"description": "Flaky white haddock fried to golden perfection w/ YP fries, southern style slaw, and our homemade tartar and cocktail sauce",
		"price": "12",
		"category": "PUB GRUB"
	},
	{
		"title": "CHICKEN N CHIPS",
		"description": "Lightly breaded jumbo chicken tenderloins w/ YP fries, southern style slaw and BBQ and honey mustard on the side",
		"price": "12",
		"category": "PUB GRUB"
	},
	{
		"title": "FRENCH DIP",
		"description": "Tender shaved steak and onion, sliced blanched mushrooms, horseradish mayo and provolone cheese atop a true South Philly roll w/ a side of au jus",
		"price": "10.5",
		"category": "PUB GRUB"
	},
	{
		"title": "BBQ CHICKEN MELT",
		"description": "Fresh grilled chicken breast w/ BBQ sauce, provolone cheese, bacon, golden battered onion rings and cole slaw on a toasted Brioche bun",
		"price": "10.5",
		"category": "PUB GRUB"
	},
	{
		"title": "MANDARIN CHICKEN WRAP",
		"description": "Grilled chicken breast, fresh greens, mandarin oranges, cranberries, pecans and crispy wontons served w/ lo-cal balsamic vinaigrette in choice of white or wheat wrap",
		"price": "10.5",
		"category": "PUB GRUB"
	},
	{
		"title": "PHILLY CHEESESTEAK",
		"description": "Philly style cheesesteak on a true South Philly roll",
		"price": "10.5",
		"category": "PUB GRUB"
	},
	{
		"title": "SPICY CHICKEN CAESAR WRAP",
		"description": "Crispy romaine lettuce, Caesar dressing, spicy breaded chicken breast, sliced tomatoes and provolone cheese in choice of white or wheat wrap",
		"price": "10.5",
		"category": "PUB GRUB"
	},
	{
		"title": "CLASSIC CHICKEN",
		"description": "Flour tortilla stuffed w/ roasted peppers and onions, diced tomato, jalapenos, tender chicken, and mozzarella and cheddar cheese",
		"price": "8",
		"category": "QUESADILLAS"
	},
	{
		"title": "BBQ CHICKEN",
		"description": "Flour tortilla stuffed w/ tender chicken, bacon, BBQ sauce, mozzarella and cheddar cheese",
		"price": "8",
		"category": "QUESADILLAS"
	},
	{
		"title": "BUFFALO CHICKEN DIP",
		"description": "Flour tortilla stuffed w/ homemade buffalo chicken dip, jalapenos, celery, onion, mozzarella and cheddar cheese",
		"price": "8",
		"category": "QUESADILLAS"
	},
	{
		"title": "SEAFOOD",
		"description": "Flour tortilla stuffed with homemade crab dip and shrimp, corn and pepper medley, onions, mozzarella, cheddar cheese and brushed with garlic butter. Side of cocktail sauce",
		"price": "8",
		"category": "QUESADILLAS"
	}
]