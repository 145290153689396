import React, { useEffect, useMemo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { FaCloudUploadAlt } from "@react-icons/all-files/fa/FaCloudUploadAlt";
import { FaCamera } from "@react-icons/all-files/fa/FaCamera";
import { MdReplay } from "@react-icons/all-files/md/MdReplay";
import { HiOutlineSparkles } from "@react-icons/all-files/hi/HiOutlineSparkles";
import { MdTouchApp } from "@react-icons/all-files/md/MdTouchApp";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router-dom";

import {
    BaseBoxProps,
    Box,
    Button,
    Flex,
    Header,
    Image,
    ImageProps,
    LoadingSkeleton,
    Stack,
    Text,
    useHasImageLoaded
} from "Atoms";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "Constants";
import { useEffectOnce } from "Hooks";
import { LoadingMessage } from "TempUtils";
import { LoadingDots } from "Molecules";
import { useLanguage } from "Providers";
import { convertHEXToRGBA, showToastError } from "Utils";
import { useMenuUploadStore, MENU_UPLOAD_STATE } from "Stores";
import { testImages } from "./TestImages";
import theme from "./QMenuTheme.json";
import { useTestDataArrowKeys } from "./useTestDataArrowKeys";
import { SellingPointsCarousel } from "./SellingPointsCarousel";
import { QAnalysisMenuAPI } from "./QAnalysisAPI";
import { SEOWebPage } from "Types";

// Only put these in a separate file cus they are huge base64 strings
const exampleImages = testImages as {
    image: string; // base64 string
    response: any;
}[];

// These are framer animation variables
const defaultVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 1 }
};

const AIRestaurantBackground = "https://qopla.s3.eu-west-1.amazonaws.com/qopla/AIRestaurantBackground.png";

const AnimatedComponent = ({ children, variants = defaultVariants }: { variants?: Variants } & BaseBoxProps) => {
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.5 }}
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            {children}
        </motion.div>
    );
};

const Nav = ({
    title,
    color,
    indicatorColor,
    colorScheme,
    ...rest
}: { title: string; color: string; colorScheme: any; indicatorColor: string } & BaseBoxProps) => {
    return (
        <Flex
            as="nav"
            position="relative"
            align="center"
            justify="space-between"
            px={[4, 8]}
            py={4}
            color="black"
            h="auto"
            zIndex={1}
            // Glassmorphism effect
            backgroundColor={convertHEXToRGBA(colorScheme.secondaryContainer, 0.5)}
            boxShadow={`1px 3px 4px 0px ${colorScheme.outline}`}
            style={{ backdropFilter: `blur(5px)`, WebkitBackdropFilter: `blur(5px)` }}
            {...rest}
        >
            <Flex align="center" mr={5} alignItems="baseline" justifyContent="space-between" w="100%">
                <Header
                    m={0}
                    as="h1"
                    size="xl"
                    fontSize="3rem"
                    color="newPrimary"
                    fontFamily="qopla"
                    textAlign="center"
                >
                    Qopla
                </Header>
                <Flex justifyContent={"flex-end"}>
                    <Box as="h3" m={0} ml={2} color={color} textAlign="center">
                        {title}
                    </Box>
                    <Box as={HiOutlineSparkles} color={color} fontSize={"1.5rem"} ml={1} />
                </Flex>
            </Flex>
        </Flex>
    );
};

// Start with a gradient from the top down 200px
// Then show the background image with a cover at 200px vertical offset
// The entire backgroud should be absolutely positioned in the parent
// The entire background should have a partial opacity
const Background = ({
    topBackgroundColor,
    bottomBackgroundColor
}: {
    topBackgroundColor: string;
    bottomBackgroundColor: string;
}) => {
    // This should match the top pixel of the background image
    const gradientColor = "#023D32";
    const offset = "200px";
    const opacity = 0.4;
    return (
        <Box position="absolute" w="100%" h="100%" opacity={0.8} maxW={"800px"}>
            <Box
                position={"absolute"}
                top={0}
                left={0}
                width={"100%"}
                height={offset}
                opacity={opacity}
                background={`linear-gradient(to bottom, ${topBackgroundColor}, ${gradientColor} 100%)`}
            />

            <Box position={"absolute"} top={offset} left={0} width={"100%"}>
                <Image
                    position={"relative"}
                    width={"100%"}
                    height={"auto"}
                    src={AIRestaurantBackground}
                    opacity={opacity}
                    objectFit={"cover"}
                    display={"block"}
                />

                <Box
                    width={"100%"}
                    height={"100%"}
                    position={"fixed"}
                    top={0}
                    left={0}
                    zIndex={-1}
                    backgroundColor={bottomBackgroundColor}
                ></Box>
            </Box>
        </Box>
    );
};

const ImagePreview = ({ src, ...rest }: ImageProps) => {
    return (
        <img
            src={src}
            style={{
                objectFit: "contain",
                borderRadius: "0.75rem",
                minWidth: "4rem",
                minHeight: "5rem",
                maxWidth: "100%",
                maxHeight: "15rem"
            }}
            {...rest}
            width="100%"
            height="auto"
        />
    );
};

export const QMenuUploadPage = () => {
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [currentTestDataIndex, setCurrentTestDataIndex] = useState<number | undefined>(undefined);

    const navigate = useNavigate();
    const { translate, translateWithArgument } = useLanguage();

    const {
        isLoading,
        base64Image,
        setBase64Image,
        loadStore,
        menuUploadState,
        setMenuUploadState,
        initialScanResults,
        setInitialScanResults,
        uploadResults,
        setUploadResults,
        clearUploadData
    } = useMenuUploadStore();

    const {
        formState: { isSubmitting },
        handleSubmit
    } = useForm<{}>({
        defaultValues: {},
        mode: "onChange"
    });

    const currentColorScheme = theme.schemes.light;
    const image = base64Image as string | undefined;
    const hasImageLoaded = useHasImageLoaded({ src: image });

    const imagePreview = useMemo(() => {
        if (image === undefined || isLoading || !hasImageLoaded)
            return <LoadingSkeleton columns={1} rows={1} height={"15rem"} width={"100%"} />;

        return <ImagePreview src={image} />;
    }, [image, isLoading, hasImageLoaded]);

    useEffectOnce(() => {
        (window as any).recaptchaOptions = {
            enterprise: true
        };

        // This loads the image from the IndexedDB into the zustand store
        loadStore();
    });

    useEffect(() => {
        if (uploadResults) {
            setMenuUploadState(MENU_UPLOAD_STATE.FINISHED);
        } else if (isSubmitting) {
            setMenuUploadState(MENU_UPLOAD_STATE.UPLOADING);
        } else if (image) {
            setMenuUploadState(MENU_UPLOAD_STATE.PREVIEW);
        } else {
            setMenuUploadState(MENU_UPLOAD_STATE.SELECTING);
        }
    }, [image, isSubmitting, uploadResults]);

    // Only for testing purposes
    useTestDataArrowKeys({
        currentTestDataIndex,
        setTestDataIndex: setCurrentTestDataIndex,
        numData: exampleImages.length
    });

    useEffect(() => {
        // This prevents setting a "default" image, e.g. `images[index ?? 0]` allowing us to start with no image
        if (currentTestDataIndex === undefined) {
            return;
        }
        onPrepareAndSetImage(exampleImages[currentTestDataIndex].image);
    }, [currentTestDataIndex]);

    const onSubmit = async () => {
        if (!image) {
            throw new Error("No image selected");
        }

        try {
            const uploadInitialScanResults = await QAnalysisMenuAPI.uploadMenuInitialScan(image);
            const uploadInitialScanResultsString = uploadInitialScanResults.results;

            // Split on new lines
            const menuItemStrings = uploadInitialScanResultsString.split("\n");
            const cleanedMenuItemStrings = menuItemStrings.map(str => str.trim()).filter(str => str.length > 0);

            // If fewer than 3 lines are returned, the menu is likely empty
            if (cleanedMenuItemStrings.length < 3) {
                throw new Error("Menu is empty, or too few items were detected. Please try again.");
            }
            setInitialScanResults(cleanedMenuItemStrings);

            // Finished initial scan, now parse the menu
            const uploadResults = await QAnalysisMenuAPI.uploadMenuParse(image);
            setUploadResults(uploadResults);
        } catch (error) {
            console.error("Error uploading menu: ", error);
            showToastError(translate("errorUploadingMenu"));
        }
    };

    const onDrop = async (files: any) => {
        const file = files[0];

        // Get Base64 image from file
        const reader = new FileReader();
        reader.onload = () => {
            const base64image = reader.result as string;
            onPrepareAndSetImage(base64image);
        };

        reader.readAsDataURL(file);
    };

    const onPrepareAndSetImage = (base64image: string) => {
        // Parse and print the size of the image in MB
        const sizeInMB = (base64image.length * 3) / 4 / 1000000;

        if (sizeInMB > 20) {
            toast.error(translate("qMenuImageTooLarge"));
            return;
        }

        setBase64Image(base64image);
    };

    /*
      Weird issue causing re-render `onBlur` after uploading an image
      This led to needing to click the button twice to submit the form
      To negate this, we need to prevent the onBlur event from firing
      According to the docs, the onBlur event can be nullified by setting

      Ref https://react-dropzone.js.org/#src
       > If you'd like to selectively turn off the default dropzone behavior for onKeyDown, onFocus and onBlur, use the {noKeyboard} property:
    */
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noKeyboard: true });

    // Remove the ref from the rootProps, it causes a warning in the console
    const { ref, ...rootProps } = getRootProps();

    const currentUrl = window.location.href;
    const isValid = !!image;
    const isSelecting = menuUploadState === MENU_UPLOAD_STATE.SELECTING;
    const isPreview = menuUploadState === MENU_UPLOAD_STATE.PREVIEW;
    const isUploading = menuUploadState === MENU_UPLOAD_STATE.UPLOADING;
    const isFinished = menuUploadState === MENU_UPLOAD_STATE.FINISHED;

    const headerString = {
        [MENU_UPLOAD_STATE.SELECTING]: translate("uploadYourMenu"),
        [MENU_UPLOAD_STATE.PREVIEW]: translate("selectedMenu"),
        [MENU_UPLOAD_STATE.UPLOADING]: translate("uploadingWithDots"),
        [MENU_UPLOAD_STATE.FINISHED]: translate("finishedProcessingMenu")
    }[menuUploadState];

    const numProducts = uploadResults?.menu_items?.length || 0;
    const canSelectImage = [MENU_UPLOAD_STATE.SELECTING, MENU_UPLOAD_STATE.PREVIEW].includes(menuUploadState);

    /**
     * Create an animated sequence of the initial scan results
     * Link to the TypedAnimation documentation: https://react-type-animation.netlify.app/examples
     */
    const animatedInitialResultsSequence: any[] = [];
    if (!!initialScanResults?.length) {
        for (let i = 0; i < initialScanResults.length; i++) {
            // String to type
            animatedInitialResultsSequence.push(initialScanResults.slice(0, i + 1).join(" ... "));
            // Delay until the next string
            animatedInitialResultsSequence.push(200);
        }
    }

    if (isLoading) {
        return <LoadingMessage />;
    }

    return (
        <Flex
            height="100%"
            width="100%"
            alignContent={"center"}
            justifyContent={"center"}
            backgroundColor={currentColorScheme.background}
        >
            <Helmet>
                <title>Qopla | {translate("qMenuUploadPageTitle")}</title>

                <meta
                    name="description"
                    content={translate("qMenuUploadSEODescription")}
                />
                <meta name="twitter:title" content={translate("qMenuUploadSEOTitle")} />
                <meta
                    name="twitter:description"
                    content={translate("qMenuUploadSEODescription")}
                />
                <meta property="og:title" content={translate("qMenuUploadSEOTitle")} />
                <meta
                    property="og:description"
                    content={translate("qMenuUploadSEODescription")}
                />

                <meta
                    name="robots"
                    content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
                />
                <link rel="canonical" href={currentUrl} />
                <script type="application/ld+json">{JSON.stringify(
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        name: `Qopla | ${translate("qMenuUploadPageTitle")}`,
                        description: translate("qMenuUploadSEODescription"),
                        headline: translate("qMenuUploadSEOTitle"),
                        url: currentUrl
                    }
                )}</script>


            </Helmet>
            <Box height="100%" width={{ base: "100%", md: "100%" }} maxW="800px">
                <Background
                    topBackgroundColor={currentColorScheme.background}
                    bottomBackgroundColor={currentColorScheme.surface}
                />
                <Nav
                    title={translate("menuPreview")}
                    color={currentColorScheme.onBackground}
                    indicatorColor={currentColorScheme.onTertiaryFixedVariant}
                    colorScheme={currentColorScheme}
                ></Nav>

                <Stack h="auto" stretch={2} alignItems="center" zIndex={1} position="relative">
                    <Header fontWeight="700" paddingTop={"1rem"}>
                        {headerString}
                    </Header>

                    {isUploading && (
                        <Flex>
                            {translate("thisCanTakeAWhile")} <LoadingDots numberOfDots={3} display="inline" />
                        </Flex>
                    )}

                    <AnimatePresence>
                        {[MENU_UPLOAD_STATE.SELECTING, MENU_UPLOAD_STATE.UPLOADING].includes(menuUploadState) && (
                            <AnimatedComponent>
                                <Text
                                    w="80%"
                                    fontWeight="bold"
                                    color={currentColorScheme.onBackground}
                                    fontSize="1rem"
                                    textAlign="center"
                                >
                                    {isSelecting && translate("takeAPicture")}
                                </Text>
                            </AnimatedComponent>
                        )}
                    </AnimatePresence>

                    {isUploading && (
                        <AnimatePresence>
                            <AnimatedComponent>
                                <Stack
                                    w={"375px"}
                                    h="auto"
                                    // If you change the stretch you need to update the paddingBottom and the `mt` in the Carousel's customPaging
                                    stretch={2}
                                    color={currentColorScheme.onBackground}
                                    background={currentColorScheme.secondaryContainer}
                                    boxShadow={`0px 2px 4px 0px ${currentColorScheme.onSecondaryContainer}`}
                                    rounded={4}
                                    padding={4}
                                    paddingTop={2}
                                    paddingBottom={16}
                                    className="slider-container"
                                >
                                    <Text
                                        color={currentColorScheme.onBackground}
                                        textAlign="center"
                                        fontSize={"1.2rem"}
                                        fontWeight={"bold"}
                                    >
                                        {translate("whileYouWait")}
                                    </Text>
                                    <SellingPointsCarousel
                                        activeColor={currentColorScheme.onBackground}
                                        inactiveColor={currentColorScheme.outlineVariant}
                                        fontWeight={"bold"}
                                        fontStyle={"italic"}
                                    />
                                </Stack>
                            </AnimatedComponent>
                        </AnimatePresence>
                    )}

                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                        <Flex w="100%" alignContent={"center"} justifyContent={"center"}>
                            <Stack
                                w="100%"
                                minH={"16rem"}
                                m={4}
                                rounded={4}
                                p={4}
                                cursor="pointer"
                                stretch={4}
                                // Glassmorphism effect
                                backgroundColor={convertHEXToRGBA(currentColorScheme.secondaryContainer, 0.5)}
                                boxShadow={`1px 3px 4px 0px ${currentColorScheme.outline}`}
                                style={{ backdropFilter: `blur(5px)`, WebkitBackdropFilter: `blur(5px)` }}
                                border={`1px dashed ${currentColorScheme.surfaceTint}`}
                                {...rootProps}
                            >
                                <input {...getInputProps()} disabled={!canSelectImage} accept="image/*" type="file" />

                                <AnimatePresence>
                                    {isSelecting && (
                                        <AnimatedComponent>
                                            <Flex mb={0} display={"inline"}>
                                                <Box
                                                    as={MdTouchApp}
                                                    color={currentColorScheme.onBackground}
                                                    fontSize={"1.3rem"}
                                                />
                                                <Text
                                                    display={"inline"}
                                                    color={currentColorScheme.onBackground}
                                                    fontSize="1.1rem"
                                                    fontWeight={"bold"}
                                                    ml={1}
                                                >
                                                    {isDragActive
                                                        ? translate("dropTheFilesHere")
                                                        : translate("tapToChoose")}
                                                </Text>
                                            </Flex>
                                        </AnimatedComponent>
                                    )}
                                </AnimatePresence>
                                {isSelecting && (
                                    <AnimatePresence>
                                        <AnimatedComponent>
                                            <Flex
                                                justifyContent="space-evenly"
                                                alignContent={"center"}
                                                alignItems={"center"}
                                                w="100%"
                                                my={12}
                                                mx={4}
                                            >
                                                <Box
                                                    as={FaCloudUploadAlt}
                                                    size="6rem"
                                                    //     TODO: This icon should be a gradient according to the design
                                                    color={currentColorScheme.onSecondaryContainer}
                                                    filter={`drop-shadow(0px 2px 2px ${currentColorScheme.onSecondaryContainer})`}
                                                />
                                                <Box
                                                    as={FaCamera}
                                                    size="6rem"
                                                    //     TODO: This icon should be a gradient according to the design
                                                    color={currentColorScheme.onSecondaryContainer}
                                                    filter={`drop-shadow(0px 2px 2px ${currentColorScheme.onSecondaryContainer})`}
                                                />
                                            </Flex>
                                        </AnimatedComponent>
                                    </AnimatePresence>
                                )}

                                {[
                                    MENU_UPLOAD_STATE.PREVIEW,
                                    MENU_UPLOAD_STATE.UPLOADING,
                                    MENU_UPLOAD_STATE.FINISHED
                                ].includes(menuUploadState) && (
                                        <Box>
                                            <Text
                                                w="100%"
                                                fontWeight="bold"
                                                color={currentColorScheme.onBackground}
                                                fontSize="1rem"
                                                textAlign="center"
                                            >
                                                {isUploading && translate("creatingLivePreview")}
                                            </Text>
                                            {isUploading && !!animatedInitialResultsSequence?.length && (
                                                <AnimatePresence>
                                                    <AnimatedComponent>
                                                        <TypeAnimation
                                                            sequence={animatedInitialResultsSequence}
                                                            cursor={false}
                                                            style={{
                                                                fontSize: "0.8rem",
                                                                fontWeight: "bold",
                                                                color: currentColorScheme.onBackground,
                                                                textAlign: "start",
                                                                flexDirection: "column-reverse",
                                                                maxHeight: "2.8rem",
                                                                display: "flex",
                                                                overflow: "hidden"
                                                            }}
                                                            speed={70}
                                                            repeat={Infinity}
                                                        />
                                                    </AnimatedComponent>
                                                </AnimatePresence>
                                            )}
                                            {imagePreview}

                                            {isPreview && (
                                                <Text
                                                    color={currentColorScheme.onBackground}
                                                    fontSize="0.8rem"
                                                    fontWeight={"bold"}
                                                    textAlign={"center"}
                                                >
                                                    {translate("tapToChange")}
                                                </Text>
                                            )}
                                        </Box>
                                    )}
                            </Stack>
                        </Flex>
                        {
                            <Box m={4} rounded={4}>
                                {isFinished && (
                                    <Flex justifyContent={"space-between"}>
                                        <Button leftIcon={MdReplay} rounded={4} onClick={clearUploadData}>
                                            {translate("startOver")}
                                        </Button>

                                        {!!numProducts && (
                                            <Button
                                                type="button"
                                                themeColor="blue"
                                                rounded={4}
                                                onClick={() => {
                                                    navigate("/qmenu/preview");
                                                }}
                                            >
                                                {translateWithArgument("seeYourPreview", numProducts)}
                                            </Button>
                                        )}
                                    </Flex>
                                )}

                                {[MENU_UPLOAD_STATE.PREVIEW, MENU_UPLOAD_STATE.UPLOADING].includes(menuUploadState) && (
                                    <Stack stretch={4} justifyContent={"center"} alignItems={"center"}>
                                        <Box mb={2}>
                                            {!captchaToken && (
                                                /** @ts-ignore */
                                                <ReCAPTCHA
                                                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                                                    onChange={(token: string | null) => {
                                                        setCaptchaToken(token);
                                                    }}
                                                />
                                            )}
                                        </Box>
                                        <Button
                                            type="submit"
                                            themeColor="blue"
                                            isLoading={isSubmitting}
                                            disabled={isSubmitting || !isValid || !captchaToken}
                                            rounded={4}
                                            fullWidth
                                        >
                                            {translate("createLivePreview")}
                                        </Button>
                                    </Stack>
                                )}
                            </Box>
                        }
                    </form>
                </Stack>
            </Box>
        </Flex>
    );
};
